import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
  gridRelative: {
    position: 'relative',
  },
  infoIcon: {
    paddingLeft: '0px',
    top: '3px',
    position: 'relative',
    color: '#52ae30',
    cursor: 'pointer',
  },
}));

export default useStyles;
