import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  footer: {
    backgroundColor: '#52ae30',
    borderRadius: '2.5rem 2.5rem 0 0',
    margin: 'auto 0 0 0',
  },
  footerContainer: {
    lineHeight: '1.125',
    padding: '30px 30px 14px 30px',
    verticalAlign: 'top',
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('md')]: {
      padding: '20px 30px 14px 30px',
    },
  },
  footerDskLogo: {
    maxWidth: '130px',
  },
  footerLogoSeparator: {
    position: 'relative',
    top: '-9px',
    fontSize: '22px',
    marginLeft: '10px',
    marginRight: '10px',
    color: '#ffffff',
  },
  footerLogoText: {
    position: 'relative',
    top: '-9px',
    color: '#ffffff',
    marginRight: '10px',
  },
  footerLogoOtp: {
    maxWidth: '116px',
    position: 'relative',
    top: '-2px',
    [theme.breakpoints.down('md')]: {
      marginLeft: theme.spacing(2),
    },
  },
  footerText: {
    color: '#ffffff',
  },
  gridItem: {
    [theme.breakpoints.down('md')]: {
      margin: 'auto',
    },
  },
}));

export default useStyles;
