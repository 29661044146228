import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
  infoIcon: {
    color: '#52ae30',
    cursor: 'pointer',
  },
}));

export default useStyles;
