import i18n from 'i18next';
import ApplicationActionTypes from './application.types';
import { fetchGetJSON, fetchPostJSON } from '../../utils/fetch.utils';

export const reloadRequiredFailure = (data) => ({
  type: ApplicationActionTypes.RELOAD_REQUIRED_FAILURE,
  payload: data,
});

export const generalServerFailure = (data) => ({
  type: ApplicationActionTypes.GENERAL_SERVER_FAILURE,
  payload: data,
});

export const resetApplicationState = (data) => ({
  type: ApplicationActionTypes.RESET_APPLICATION_STATE,
  payload: data,
});

const handleFetchError = (dispatch) => (error) => {
  if (error === 400) {
    dispatch(
      reloadRequiredFailure({
        reloadRequiredMessage: i18n.t('error.reload-required-message.new-version'),
        reloadRequiredButtonText: i18n.t('error.reload-required-button-text.new-version'),
      })
    );
  } else if (error === 401) {
    dispatch(resetApplicationState({ isInitialDataLoaded: true }));
  } else {
    dispatch(generalServerFailure());
  }
};

export const goToPreviousStep = (currentStepData) => ({
  type: ApplicationActionTypes.GO_TO_PREVIOUS_STEP,
  payload: currentStepData,
});

export const setErrorOccured = (errorOccured) => ({
  type: ApplicationActionTypes.SET_ERROR_OCCURED,
  payload: { errorOccured },
});

export const setAcceptedPersonalDataConsent = (acceptedPersonalDataConsent) => ({
  type: ApplicationActionTypes.SET_ACCEPTED_PERSONAL_DATA_CONSENT,
  payload: { acceptedPersonalDataConsent },
});

export const setApplicantId = (applicantId) => ({
  type: ApplicationActionTypes.SET_APPLICANT_ID,
  payload: { applicantId },
});

export const resetValue = (data) => ({
  type: ApplicationActionTypes.RESET_VALUE,
  payload: data,
});

export const loginSuccess = (data) => ({
  type: ApplicationActionTypes.LOGIN_SUCCESS,
  payload: data,
});

export const loginAsync = (data, dispatch, redirectFn) => {
  dispatch(resetValue({ loginErrorMessage: '' }));

  return fetchPostJSON(`${process.env.REACT_APP_APPLICATION_API_URI}/Session/Login`, data)
    .then((response) => {
      dispatch(loginSuccess(response));
      if (!response.loginErrorMessage) {
        redirectFn();
      }
    })
    .catch(handleFetchError(dispatch));
};

export const resetPasswordStart = () => ({
  type: ApplicationActionTypes.RESET_PASSWORD_START,
});

export const resetPasswordAsync = (data, dispatch) => {
  dispatch(resetPasswordStart());

  return fetchPostJSON(`${process.env.REACT_APP_APPLICATION_API_URI}/Session/ResetPassword`, data);
};

export const saveClientDataStart = () => ({
  type: ApplicationActionTypes.SAVE_CLIENT_DATA_START,
});

export const saveClientDataSuccess = (data) => ({
  type: ApplicationActionTypes.SAVE_CLIENT_DATA_SUCCESS,
  payload: data,
});

export const saveClientDataAsync = (data, dispatch) => {
  dispatch(saveClientDataStart());

  fetchPostJSON(`${process.env.REACT_APP_APPLICATION_API_URI}/Requests/SaveClientData`, data)
    .then((response) => {
      dispatch(saveClientDataSuccess({ ...data, ...response }));
    })
    .catch(handleFetchError(dispatch));
};

export const saveClientCredentialsStart = () => ({
  type: ApplicationActionTypes.SAVE_CLIENT_CREDENTIALS_START,
});

export const saveClientCredentialsSuccess = (data) => ({
  type: ApplicationActionTypes.SAVE_CLIENT_CREDENTIALS_SUCCESS,
  payload: data,
});

export const saveClientCredentialsAsync = (data, dispatch, redirectFn) => {
  dispatch(saveClientCredentialsStart());

  fetchPostJSON(`${process.env.REACT_APP_APPLICATION_API_URI}/Requests/SaveClientCredentials`, data)
    .then((response) => {
      dispatch(saveClientCredentialsSuccess(response));
      if (!response.errorOccurred) {
        redirectFn();
      }
    })
    .catch(handleFetchError(dispatch));
};

export const generateVerificationCodeStart = () => ({
  type: ApplicationActionTypes.GENERATE_VERIFICATION_CODE_START,
});

export const generateVerificationCodeSuccess = (data) => ({
  type: ApplicationActionTypes.GENERATE_VERIFICATION_CODE_SUCCESS,
  payload: data,
});

export const generateVerificationCodeAsync = (requestNumber, dispatch, callbackFn) => {
  dispatch(generateVerificationCodeStart());

  return fetchGetJSON(
    `${process.env.REACT_APP_APPLICATION_API_URI}/Requests/GenerateVerificationCode/${requestNumber}`
  )
    .then((response) => {
      dispatch(generateVerificationCodeSuccess(response));
      callbackFn(!response.requestCannotContinue);
    })
    .catch(handleFetchError(dispatch));
};

export const changeCondition = (data) => ({
  type: ApplicationActionTypes.CHANGE_CONDITION,
  payload: data,
});

export const saveContactPersonDataStart = () => ({
  type: ApplicationActionTypes.SAVE_CONTACT_PERSON_DATA_START,
});

export const saveContactPersonDataSuccess = (data) => ({
  type: ApplicationActionTypes.SAVE_CONTACT_PERSON_DATA_SUCCESS,
  payload: data,
});

export const saveContactPersonDataAsync = (data, dispatch) => {
  dispatch(saveContactPersonDataStart());

  fetchPostJSON(`${process.env.REACT_APP_APPLICATION_API_URI}/Requests/SaveContactPersonData`, data)
    .then((response) => {
      dispatch(saveContactPersonDataSuccess({ ...data, ...response }));
    })
    .catch(handleFetchError(dispatch));
};

export const saveAdditionalServicesStart = () => ({
  type: ApplicationActionTypes.SAVE_ADDITIONAL_SERVICES_START,
});

export const saveAdditionalServicesSuccess = (data) => ({
  type: ApplicationActionTypes.SAVE_ADDITIONAL_SERVICES_SUCCESS,
  payload: data,
});

export const saveAdditionalServicesAsync = (data, dispatch) => {
  dispatch(saveAdditionalServicesStart());

  return fetchPostJSON(
    `${process.env.REACT_APP_APPLICATION_API_URI}/Requests/SaveAcceptedAdditionalServices`,
    data
  )
    .then((response) => {
      dispatch(saveAdditionalServicesSuccess({ ...data, ...response }));
      return response;
    })
    .catch(handleFetchError(dispatch));
};

export const startSessionStart = () => ({
  type: ApplicationActionTypes.START_SESSION_START,
});

export const startSessionSuccess = (data) => ({
  type: ApplicationActionTypes.START_SESSION_SUCCESS,
  payload: data,
});

export const startSessionAsync = (queryString, dispatch, redirectFn) => {
  dispatch(startSessionStart());

  fetchGetJSON(`${process.env.REACT_APP_APPLICATION_API_URI}/Session/Get${queryString}`)
    .then((response) => {
      dispatch(startSessionSuccess(response));
      redirectFn();
    })
    .catch(handleFetchError(dispatch));
};

export const loadApplicantDataStart = () => ({
  type: ApplicationActionTypes.LOAD_APPLICANT_DATA_START,
});

export const loadApplicantDataSuccess = (data) => ({
  type: ApplicationActionTypes.LOAD_APPLICANT_DATA_SUCCESS,
  payload: data,
});

export const loadApplicantDataFailure = (data) => ({
  type: ApplicationActionTypes.LOAD_APPLICANT_DATA_FAILURE,
  payload: data,
});

export const setIsOtherApplicantSelected = (isApplicantNameOtherSelected) => ({
  type: ApplicationActionTypes.SET_IS_APPLICANT_NAME_OTHER_SELECTED,
  payload: { isApplicantNameOtherSelected },
});

export const loadApplicantDataAsync = (applicantType, dispatch) => {
  dispatch(loadApplicantDataStart());

  return fetchGetJSON(
    `${process.env.REACT_APP_APPLICATION_API_URI}/Requests/GetApplicantData/${applicantType}`
  )
    .then((data) => {
      dispatch(loadApplicantDataSuccess(data));
    })
    .catch((error) => {
      dispatch(loadApplicantDataFailure({ errorMessage: error.message }));
    });
};

export const saveApplicantDataStart = () => ({
  type: ApplicationActionTypes.SAVE_APPLICANT_DATA_START,
});

export const saveApplicantDataSuccess = (data) => ({
  type: ApplicationActionTypes.SAVE_APPLICANT_DATA_SUCCESS,
  payload: data,
});

export const saveApplicantDataAsync = (data, dispatch) => {
  dispatch(saveApplicantDataStart());

  fetchPostJSON(`${process.env.REACT_APP_APPLICATION_API_URI}/Requests/SaveApplicantData`, data)
    .then((response) => {
      dispatch(saveApplicantDataSuccess({ ...data, ...response }));
    })
    .catch(handleFetchError(dispatch));
};

export const saveMerchantDataStart = () => ({
  type: ApplicationActionTypes.SAVE_MERCHANT_DATA_START,
});

export const saveMerchantDataSuccess = (data) => ({
  type: ApplicationActionTypes.SAVE_MERCHANT_DATA_SUCCESS,
  payload: data,
});

export const saveMerchantDataAsync = (data, dispatch) => {
  dispatch(saveMerchantDataStart());

  fetchPostJSON(`${process.env.REACT_APP_APPLICATION_API_URI}/Requests/SaveMerchantData`, data)
    .then((response) => {
      dispatch(saveMerchantDataSuccess({ ...data, ...response }));
    })
    .catch(handleFetchError(dispatch));
};

export const saveActivityDataStart = () => ({
  type: ApplicationActionTypes.SAVE_ACTIVITY_DATA_START,
});

export const saveActivityDataSuccess = (data) => ({
  type: ApplicationActionTypes.SAVE_ACTIVITY_DATA_SUCCESS,
  payload: data,
});

export const saveActivityDataAsync = (data, dispatch) => {
  dispatch(saveActivityDataStart());

  fetchPostJSON(`${process.env.REACT_APP_APPLICATION_API_URI}/Requests/SaveActivityData`, data)
    .then((response) => {
      dispatch(saveActivityDataSuccess({ ...data, ...response }));
    })
    .catch(handleFetchError(dispatch));
};

export const saveIbanDataStart = () => ({
  type: ApplicationActionTypes.SAVE_IBAN_DATA_START,
});

export const saveIbanDataSuccess = (data) => ({
  type: ApplicationActionTypes.SAVE_IBAN_DATA_SUCCESS,
  payload: data,
});

export const saveIbanDataAsync = (data, dispatch) => {
  dispatch(saveIbanDataStart());
  fetchPostJSON(`${process.env.REACT_APP_APPLICATION_API_URI}/Requests/SaveIban`, data)
    .then((response) => {
      dispatch(saveIbanDataSuccess({ ...data, ...response }));
    })
    .catch(handleFetchError(dispatch));
};

export const clearApplicantForm = () => ({
  type: ApplicationActionTypes.CLEAR_APPLICANT_FORM,
  payload: {
    applicantNames: '',
    applicantIdentifier: '',
  },
});

export const signOut = (callbackFn) => {
  return fetchPostJSON(`${process.env.REACT_APP_APPLICATION_API_URI}/Session/SignOut`).then(() => {
    if (callbackFn) {
      callbackFn();
    }
  });
};
