/* eslint-disable react/jsx-no-duplicate-props */
import React, { useImperativeHandle } from 'react';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import InputAdornment from '@mui/material/InputAdornment';
import WbIncandescentTwoToneIcon from '@mui/icons-material/WbIncandescentTwoTone';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import MenuItem from '@mui/material/MenuItem';
import InfoDialog from '../../info-dialog/info-dialog.component';
import {
  saveMerchantDataAsync,
  changeCondition,
} from '../../../providers/application/application.actions';
import {
  useApplicationState,
  useApplicationDispatch,
} from '../../../providers/application/application.provider';
import { requiredTextField } from '../../../utils/validation.utils';
import useStyles from './merchant-data-step.styles';
import Constants from '../../../utils/constants';

const MerchantDataStep = ({ formRef, backRef, currentStepId }) => {
  const { register, errors, handleSubmit, control, getValues } = useForm();
  const { t } = useTranslation();
  const classes = useStyles();

  const {
    merchantName,
    merchantLocation,
    merchantAddress,
    merchantPostCode,
    isMerchantNameFromRegix,
    isMerchantAddressFromRegix,
    isMerchantLocationFromRegix,
    isMerchantPostCodeFromRegix,
    applicantType,
    terminalType,
    terminalTypeOther,
    isContactAddressSameAsRegistrationAddress,
    contactAddressProvince,
    contactAddressMunicipality,
    contactAddressSettlement,
    contactAddressLocation,
    contactAddressPostCode,
    merchantDataComment,
    isReadOnly,
    terminalCurrency,
    paymentPageLanguage,
    nomenclatures: {
      applicantTypes,
      terminalTypes,
      terminalCurrencies,
      paymentPageLanguages,
      terminalTypeOtherId,
      terminalTypeVirtualPosId,
      paymentPageLanguageBulgarianId,
      terminalCurrencyBGNId,
    },
  } = useApplicationState();

  const dispatch = useApplicationDispatch();

  const onSubmit = () => {
    const data = getValues();
    saveMerchantDataAsync(
      {
        ...data,
        isContactAddressSameAsRegistrationAddress,
        currentStepId,
        isMerchantNameFromRegix,
        isMerchantAddressFromRegix,
        isMerchantLocationFromRegix,
        isMerchantPostCodeFromRegix,
      },
      dispatch
    );
  };

  const handleChange = (e) => {
    dispatch(changeCondition({ [e.target.name]: e.target.value === 'true' }));
  };

  const [isOtherTerminalSelected, setIsTerminalTypeOtherSelected] = React.useState(
    terminalType === terminalTypeOtherId
  );

  const [isTerminalTypeVirtualPosSelected, setIsTerminalTypeVirtualPosSelected] = React.useState(
    terminalType === terminalTypeVirtualPosId
  );

  const handleTerminalTypeChange = (selectedValue) => {
    setIsTerminalTypeOtherSelected(selectedValue === terminalTypeOtherId);
    setIsTerminalTypeVirtualPosSelected(selectedValue === terminalTypeVirtualPosId);
  };

  const constructFormData = (data) => {
    return {
      ...data,
      isContactAddressSameAsRegistrationAddress:
        isContactAddressSameAsRegistrationAddress !== ''
          ? isContactAddressSameAsRegistrationAddress
          : null,
    };
  };

  useImperativeHandle(backRef, () => ({
    getValues() {
      return constructFormData(getValues());
    },
  }));

  const [showInfoDialog, setShowInfoDialog] = React.useState(false);
  const [infoDialogContent, setInfoDialogContent] = React.useState('');
  const [infoDialogTitle, setInfoDialogTitle] = React.useState('');

  const infoDialogOpen = (text, title) => {
    setInfoDialogContent(text);
    setInfoDialogTitle(title);
    setShowInfoDialog(true);
  };

  const infoDialogClose = () => {
    setShowInfoDialog(false);
  };

  return (
    <form ref={formRef} onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2}>
        <Grid container className={classes.merchantSectionInfo}>
          <Grid item xs={1} className={classes.iconGrid}>
            <WbIncandescentTwoToneIcon className={classes.bulbIcon} />
          </Grid>
          <Grid item xs={11} className={classes.infoText}>
            <span>{t('merchant-data-step.section-info')}</span>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <TextField
                label={`${t('merchant-name')} *`}
                name='merchantName'
                defaultValue={merchantName}
                inputProps={{
                  maxLength: 250,
                }}
                disabled={isMerchantNameFromRegix || isReadOnly}
                error={!!errors.merchantName}
                helperText={errors.merchantName && errors.merchantName.message}
                inputRef={register({
                  validate: requiredTextField,
                  pattern: {
                    value: !isMerchantNameFromRegix ? Constants.CYRILLIC_REGEX : null,
                    message: t('validation.text-field.not-cyrillic'),
                  },
                })}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label={`${t('merchant-location')} *`}
                name='merchantLocation'
                defaultValue={merchantLocation}
                inputProps={{
                  maxLength: 250,
                }}
                disabled={isMerchantLocationFromRegix || isReadOnly}
                error={!!errors.merchantLocation}
                helperText={errors.merchantLocation && errors.merchantLocation.message}
                inputRef={register({
                  validate: requiredTextField,
                  pattern: {
                    value: !isMerchantLocationFromRegix ? Constants.CYRILLIC_REGEX : null,
                    message: t('validation.text-field.not-cyrillic'),
                  },
                })}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label={`${t('merchant-address')} *`}
                name='merchantAddress'
                defaultValue={merchantAddress}
                inputProps={{
                  maxLength: 500,
                }}
                disabled={isMerchantAddressFromRegix || isReadOnly}
                error={!!errors.merchantAddress}
                helperText={errors.merchantAddress && errors.merchantAddress.message}
                inputRef={register({
                  validate: requiredTextField,
                  pattern: {
                    value: !isMerchantAddressFromRegix ? Constants.CYRILLIC_REGEX : null,
                    message: t('validation.text-field.not-cyrillic'),
                  },
                })}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label={`${t('merchant-data-step.post-code')} *`}
                name='merchantPostCode'
                defaultValue={merchantPostCode}
                inputProps={{
                  maxLength: 4,
                }}
                disabled={isMerchantPostCodeFromRegix || isReadOnly}
                error={!!errors.merchantPostCode}
                helperText={errors.merchantPostCode && errors.merchantPostCode.message}
                inputRef={register({
                  validate: requiredTextField,
                  pattern: {
                    value: !isMerchantPostCodeFromRegix ? /^[1-9][0-9]{3}$/ : null,
                    message: t('validation.invalid-bg-post-code', { count: '4' }),
                  },
                })}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name='terminalType'
                defaultValue={terminalType || ''}
                rules={{
                  required: {
                    value: true,
                    message: t('validation.text-field.required'),
                  },
                }}
                render={(props) => (
                  <TextField
                    label={`${t('terminal-type')} *`}
                    value={props.value}
                    select
                    error={!!errors.terminalType}
                    helperText={errors.terminalType && errors.terminalType.message}
                    onChange={(e) => {
                      handleTerminalTypeChange(e.target.value);
                      props.onChange(e);
                    }}
                    disabled={isReadOnly}
                  >
                    {terminalTypes.map((item) => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
                control={control}
              />
            </Grid>
            {isOtherTerminalSelected && (
              <Grid item xs={12} sm={6}>
                <TextField
                  multiline
                  label={`${t('merchant-data-step.terminal-type-other')} *`}
                  name='terminalTypeOther'
                  inputProps={{
                    maxLength: 250,
                  }}
                  inputRef={register({
                    pattern: {
                      value: Constants.CYRILLIC_REGEX,
                      message: t('validation.text-field.not-cyrillic'),
                    },
                    required: {
                      value: isOtherTerminalSelected,
                      message: t('validation.text-field.required'),
                    },
                  })}
                  defaultValue={terminalTypeOther}
                  error={!!errors.terminalTypeOther}
                  helperText={errors.terminalTypeOther && errors.terminalTypeOther.message}
                  disabled={isReadOnly}
                />
              </Grid>
            )}
            {isTerminalTypeVirtualPosSelected && (
              <>
                <Grid item xs={12} sm={6}>
                  <Controller
                    control={control}
                    label={`${t('merchant-data-step.terminal-currency')} *`}
                    name='terminalCurrency'
                    defaultValue={terminalCurrency || terminalCurrencyBGNId}
                    select
                    disabled={isReadOnly}
                    error={!!errors.terminalCurrency}
                    helperText={errors.terminalCurrency && errors.terminalCurrency.message}
                    rules={{
                      required: {
                        value: true,
                        message: t('validation.text-field.required'),
                      },
                    }}
                    as={
                      <TextField
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position='end'>
                              <InfoOutlinedIcon
                                className={classes.infoIcon}
                                onClick={() =>
                                  infoDialogOpen(
                                    t('merchant-data-step.terminal-currency.info-dialog'),
                                    t('merchant-data-step.terminal-currency')
                                  )
                                }
                              />
                            </InputAdornment>
                          ),
                        }}
                        inputProps={{
                          classes: {
                            icon: classes.icon,
                          },
                        }}
                      >
                        {terminalCurrencies.map((item) => (
                          <MenuItem key={item.id} value={item.id}>
                            {item.name}
                          </MenuItem>
                        ))}
                      </TextField>
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Controller
                    control={control}
                    label={`${t('merchant-data-step.payment-page-language')} *`}
                    name='paymentPageLanguage'
                    defaultValue={paymentPageLanguage || paymentPageLanguageBulgarianId}
                    select
                    disabled={isReadOnly}
                    error={!!errors.paymentPageLanguage}
                    helperText={errors.paymentPageLanguage && errors.paymentPageLanguage.message}
                    rules={{
                      required: {
                        value: true,
                        message: t('validation.text-field.required'),
                      },
                    }}
                    as={
                      <TextField
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position='end'>
                              <InfoOutlinedIcon
                                className={classes.infoIcon}
                                onClick={() =>
                                  infoDialogOpen(
                                    t('merchant-data-step.payment-page-language.info-dialog'),
                                    t('merchant-data-step.payment-page-language')
                                  )
                                }
                              />
                            </InputAdornment>
                          ),
                        }}
                        inputProps={{
                          classes: {
                            icon: classes.icon,
                          },
                        }}
                      >
                        {paymentPageLanguages.map((item) => (
                          <MenuItem key={item.id} value={item.id}>
                            {item.name}
                          </MenuItem>
                        ))}
                      </TextField>
                    }
                  />
                </Grid>
              </>
            )}
            <Grid item xs={12} sm={6}>
              <Controller
                control={control}
                label={`${t('applicant-title')} *`}
                name='applicantType'
                defaultValue={applicantType || ''}
                select
                disabled={isReadOnly}
                error={!!errors.applicantType}
                helperText={errors.applicantType && errors.applicantType.message}
                rules={{
                  required: {
                    value: true,
                    message: t('validation.text-field.required'),
                  },
                }}
                as={
                  <TextField>
                    {applicantTypes.map((item) => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </TextField>
                }
              />
            </Grid>
            <Grid item xs={12}>
              <span>{`${t(
                'merchant-data-step.contact-address-matches-registration-address'
              )} *`}</span>
              <RadioGroup
                name='controlled-radio-buttons-group'
                value={isContactAddressSameAsRegistrationAddress}
                row
              >
                <FormControlLabel
                  inputRef={register({
                    required: {
                      value: true,
                      message: t('validation.text-field.required'),
                    },
                  })}
                  name='isContactAddressSameAsRegistrationAddress'
                  className={classes.radioButton}
                  value
                  onClick={handleChange}
                  control={<Radio />}
                  label={t('general.yes')}
                  disabled={isReadOnly}
                />
                <FormControlLabel
                  inputRef={register({
                    required: {
                      value: true,
                      message: t('validation.text-field.required'),
                    },
                  })}
                  name='isContactAddressSameAsRegistrationAddress'
                  className={classes.radioButton}
                  value={false}
                  onClick={handleChange}
                  control={<Radio />}
                  label={t('general.no')}
                  disabled={isReadOnly}
                />
              </RadioGroup>
              {errors.isContactAddressSameAsRegistrationAddress && (
                <p className='radioButtonsErrorMessage'>
                  {errors.isContactAddressSameAsRegistrationAddress.message}
                </p>
              )}
            </Grid>
            {isContactAddressSameAsRegistrationAddress === false && (
              <>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label={`${t('general.district')} *`}
                    name='contactAddressProvince'
                    defaultValue={contactAddressProvince}
                    inputProps={{
                      maxLength: 40,
                    }}
                    error={!!errors.contactAddressProvince}
                    helperText={
                      errors.contactAddressProvince && errors.contactAddressProvince.message
                    }
                    inputRef={register({
                      validate: requiredTextField,
                      pattern: {
                        value: Constants.CYRILLIC_REGEX,
                        message: t('validation.text-field.not-cyrillic'),
                      },
                    })}
                    disabled={isReadOnly}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label={`${t('general.municipality')} *`}
                    name='contactAddressMunicipality'
                    defaultValue={contactAddressMunicipality}
                    inputProps={{
                      maxLength: 40,
                    }}
                    error={!!errors.contactAddressMunicipality}
                    helperText={
                      errors.contactAddressMunicipality && errors.contactAddressMunicipality.message
                    }
                    inputRef={register({
                      validate: requiredTextField,
                      pattern: {
                        value: Constants.CYRILLIC_REGEX,
                        message: t('validation.text-field.not-cyrillic'),
                      },
                    })}
                    disabled={isReadOnly}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label={`${t('general.territorial-unit')} *`}
                    name='contactAddressSettlement'
                    defaultValue={contactAddressSettlement}
                    inputProps={{
                      maxLength: 40,
                    }}
                    error={!!errors.contactAddressSettlement}
                    helperText={
                      errors.contactAddressSettlement && errors.contactAddressSettlement.message
                    }
                    inputRef={register({
                      validate: requiredTextField,
                      pattern: {
                        value: Constants.CYRILLIC_REGEX,
                        message: t('validation.text-field.not-cyrillic'),
                      },
                    })}
                    disabled={isReadOnly}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label={`${t('general.address')} *`}
                    name='contactAddressLocation'
                    defaultValue={contactAddressLocation}
                    inputProps={{
                      maxLength: 40,
                    }}
                    error={!!errors.contactAddressLocation}
                    helperText={
                      errors.contactAddressLocation && errors.contactAddressLocation.message
                    }
                    inputRef={register({
                      validate: requiredTextField,
                      pattern: {
                        value: Constants.CYRILLIC_REGEX,
                        message: t('validation.text-field.not-cyrillic'),
                      },
                    })}
                    disabled={isReadOnly}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label={`${t('general.post-code')} *`}
                    name='contactAddressPostCode'
                    defaultValue={contactAddressPostCode}
                    inputProps={{
                      maxLength: 4,
                    }}
                    error={!!errors.contactAddressPostCode}
                    helperText={
                      errors.contactAddressPostCode && errors.contactAddressPostCode.message
                    }
                    inputRef={register({
                      validate: requiredTextField,
                      pattern: {
                        value: /^[1-9][0-9]{3}$/,
                        message: t('validation.invalid-bg-post-code', { count: '4' }),
                      },
                    })}
                    disabled={isReadOnly}
                  />
                </Grid>
              </>
            )}
            <Grid item xs={12}>
              <TextField
                fullWidth
                multiline
                margin='normal'
                label={t('general.comment')}
                name='merchantDataComment'
                inputProps={{
                  maxLength: 300,
                }}
                inputRef={register({
                  pattern: {
                    value: Constants.CYRILLIC_REGEX,
                    message: t('validation.text-field.not-cyrillic'),
                  },
                })}
                defaultValue={merchantDataComment}
                error={!!errors.merchantDataComment}
                helperText={errors.merchantDataComment && errors.merchantDataComment.message}
                disabled={isReadOnly}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <InfoDialog open={showInfoDialog} title={infoDialogTitle} handleClose={infoDialogClose}>
        {infoDialogContent}
      </InfoDialog>
    </form>
  );
};

export default MerchantDataStep;
