/* eslint-disable react/jsx-no-duplicate-props */
import React, { useImperativeHandle, useState } from 'react';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import { useTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';
import MenuItem from '@mui/material/MenuItem';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import InputAdornment from '@mui/material/InputAdornment';
import FormControlLabel from '@mui/material/FormControlLabel';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import InfoDialog from '../../info-dialog/info-dialog.component';
import {
  useApplicationState,
  useApplicationDispatch,
} from '../../../providers/application/application.provider';
import {
  saveActivityDataAsync,
  changeCondition,
} from '../../../providers/application/application.actions';
import { requiredTextField } from '../../../utils/validation.utils';
import useStyles from './activity-data-step.styles';
import ProductData from '../product-data/product-data.component';
import Constants from '../../../utils/constants';

const ActivityDataStep = ({ formRef, backRef, currentStepId }) => {
  const classes = useStyles();
  const { handleSubmit, errors, control, register, getValues, clearErrors, unregister } = useForm();
  const { t } = useTranslation();

  const {
    merchantNameBG,
    merchantNameEN,
    websiteURL,
    websiteIP,
    websiteOwnership,
    websiteOwnershipOther,
    isActivityFromRegix,
    activity,
    isMainActivityClassificationFromRegix,
    mainActivityClassification,
    commercialPOSActivity,
    averageOrderPrice,
    previousYearTurnover,
    averageMonthlyPOSTurnover,
    averageMonthlyTransactions,
    activityComment,
    objectAddressProvince,
    objectAddressMunicipality,
    objectAddressSettlement,
    objectAddressLocation,
    objectAddressPostCode,
    products,
    isReadOnly,
    websitePlatformType,
    websitePlatformTypeOther,
    isObjectAddressSameAsRegistrationAddress,
    terminalType,
    objectOwnership,
    objectOwnershipOther,
    posTerminalsCount,
    isSeasonalObject,
    seasonalityId,
    seasonalityComment,
    posDeviceTypeId,
    nomenclatures: {
      websiteOwnerships,
      websiteOwnershipOtherId,
      maxActivityDataProducts,
      websitePlatformTypes,
      websitePlatformTypeOtherId,
      terminalTypeVirtualPosId,
      terminalTypeSoftwarePosId,
      objectOwnershipOtherId,
      objectOwnerships,
      seasonalities,
      posDeviceTypes,
      temporaryUsageId,
    },
  } = useApplicationState();
  const dispatch = useApplicationDispatch();

  const [isOtherWebsiteOwnershipSelected, setIsOtherwebsiteOwnershipSelected] = React.useState(
    websiteOwnership === websiteOwnershipOtherId
  );

  const handleWebsiteOwnershipChange = (selectedValue) => {
    setIsOtherwebsiteOwnershipSelected(selectedValue === websiteOwnershipOtherId);
  };

  const [isTemporaryUsageSelected, setIsTemporaryUsageSelected] = React.useState(
    seasonalityId === temporaryUsageId
  );

  const handleSeasonalityChange = (selectedValue) => {
    setIsTemporaryUsageSelected(selectedValue === temporaryUsageId);
  };

  const [isOtherObjectOwnershipSelected, setIsOtherobjectOwnershipSelected] = React.useState(
    objectOwnership === objectOwnershipOtherId
  );

  const handleObjectOwnershipChange = (selectedValue) => {
    setIsOtherobjectOwnershipSelected(selectedValue === objectOwnershipOtherId);
  };

  const [isOtherWebsitePlatformTypeSelected, setIsOtherWebsitePlatformTypeSelected] =
    React.useState(websitePlatformType === websitePlatformTypeOtherId);

  const handleWebsitePlatformTypeChange = (selectedValue) => {
    setIsOtherWebsitePlatformTypeSelected(selectedValue === websitePlatformTypeOtherId);
  };

  const [showInfoDialog, setShowInfoDialog] = React.useState(false);
  const [infoDialogContent, setInfoDialogContent] = React.useState('');
  const [infoDialogTitle, setInfoDialogTitle] = React.useState('');

  const infoDialogOpen = (text, title) => {
    setInfoDialogContent(text);
    setInfoDialogTitle(title);
    setShowInfoDialog(true);
  };

  const infoDialogClose = () => {
    setShowInfoDialog(false);
  };

  const [productsData, setProductsData] = useState(products);
  const [maxIdentifier, setMaxIdentifier] = useState(products.length);

  const handleRemoveProductData = (productDataIdentifier) => {
    if (productsData.length > 1) {
      const data = getValues();
      setProductsData(
        productsData
          .map((element, index) => {
            return {
              ...element,
              ...data.products[index],
            };
          })
          .filter((product) => product.identifier !== productDataIdentifier)
      );
      data.products.map((_, index) => {
        clearErrors(`products[${index}]`);
        return unregister([
          `products[${index}].productGroups`,
          `products[${index}].licenseOther`,
          `products[${index}].ownProductTypeOther`,
        ]);
      });
    }
  };

  const handleAddProductData = () => {
    if (productsData.length < maxActivityDataProducts) {
      setProductsData([
        ...productsData,
        {
          identifier: maxIdentifier,
          productGroups: '',
          license: null,
          licenseOther: '',
          productType: null,
          ownProductType: null,
          ownProductTypeOther: '',
        },
      ]);
      setMaxIdentifier(maxIdentifier + 1);
    }
  };

  const handleChangeProductData = (newData) => {
    const data = getValues();
    setProductsData(
      productsData.map((element, index) => {
        return {
          ...element,
          ...data.products[index],
          ...newData,
        };
      })
    );
  };

  const handleChange = (e) => {
    dispatch(changeCondition({ [e.target.name]: e.target.value === 'true' }));
  };

  const constructFormData = (data) => {
    return {
      ...data,
      isObjectAddressSameAsRegistrationAddress:
        isObjectAddressSameAsRegistrationAddress !== ''
          ? isObjectAddressSameAsRegistrationAddress
          : null,
      isSeasonalObject: isSeasonalObject !== '' ? isSeasonalObject : null,
      isActivityFromRegix,
      isMainActivityClassificationFromRegix,
      products: productsData.map((element, index) => {
        return {
          ...element,
          ...data.products[index],
        };
      }),
      currentStepId,
      terminalType,
    };
  };

  useImperativeHandle(backRef, () => ({
    getValues() {
      return constructFormData(getValues());
    },
  }));

  const onSubmit = () => {
    const data = getValues();
    saveActivityDataAsync(constructFormData(data), dispatch);
  };

  return (
    <form ref={formRef} onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <TextField
            label={`${t('activity-data-step.trade-name-bg')} *`}
            name='merchantNameBG'
            defaultValue={merchantNameBG}
            multiline
            inputProps={{
              maxLength: 250,
            }}
            error={!!errors.merchantNameBG}
            helperText={errors.merchantNameBG && errors.merchantNameBG.message}
            inputRef={register({
              validate: requiredTextField,
              pattern: {
                value: Constants.CYRILLIC_REGEX,
                message: t('validation.text-field.not-cyrillic'),
              },
            })}
            disabled={isReadOnly}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label={`${t('activity-data-step.trade-name-en')} *`}
            name='merchantNameEN'
            defaultValue={merchantNameEN}
            multiline
            inputProps={{
              maxLength: 250,
            }}
            error={!!errors.merchantNameEN}
            helperText={errors.merchantNameEN && errors.merchantNameEN.message}
            inputRef={register({
              validate: requiredTextField,
            })}
            disabled={isReadOnly}
          />
        </Grid>
        {terminalType === terminalTypeVirtualPosId ? (
          <>
            <Grid item xs={12} sm={6}>
              <TextField
                label={`${t('activity-data-step.website-url')} *`}
                name='websiteURL'
                defaultValue={websiteURL}
                multiline
                inputProps={{
                  maxLength: 250,
                }}
                error={!!errors.websiteURL}
                helperText={errors.websiteURL && errors.websiteURL.message}
                inputRef={register({
                  validate: requiredTextField,
                })}
                disabled={isReadOnly}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                name='websiteIP'
                label={t('activity-data-step.ip-address')}
                defaultValue={websiteIP}
                placeholder={t('activity-data-step.ip-placeholder')}
                inputProps={{
                  maxLength: 15,
                }}
                error={!!errors.websiteIP}
                helperText={errors.websiteIP && errors.websiteIP.message}
                inputRef={register({
                  pattern: {
                    value:
                      /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/,
                    message: t('validation.text-field.invalid'),
                  },
                })}
                disabled={isReadOnly}
              />
            </Grid>
            <Grid item xs={12} sm={6} className={classes.gridRelative}>
              <Controller
                name='websiteOwnership'
                defaultValue={websiteOwnership || ''}
                rules={{
                  required: {
                    value: true,
                    message: t('validation.text-field.required'),
                  },
                }}
                disabled={isReadOnly}
                render={(props) => (
                  <TextField
                    label={`${t('client-data-step.website-ownership')} *`}
                    value={props.value}
                    select
                    error={!!errors.websiteOwnership}
                    helperText={errors.websiteOwnership && errors.websiteOwnership.message}
                    onChange={(e) => {
                      handleWebsiteOwnershipChange(e.target.value);
                      props.onChange(e);
                    }}
                    className={classes.selectDropdown}
                    disabled={isReadOnly}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='end'>
                          <InfoOutlinedIcon
                            className={classes.infoIcon}
                            onClick={() =>
                              infoDialogOpen(
                                t('activity-data-step.website-ownership.info-dialog'),
                                t('client-data-step.website-ownership')
                              )
                            }
                          />
                        </InputAdornment>
                      ),
                    }}
                    inputProps={{
                      classes: {
                        icon: classes.icon,
                      },
                    }}
                  >
                    {websiteOwnerships.map((item) => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
                control={control}
              />
            </Grid>
            {isOtherWebsiteOwnershipSelected ? (
              <Grid item xs={12} sm={6}>
                <TextField
                  label={`${t('activity-data-step.website-ownership-other')} *`}
                  name='websiteOwnershipOther'
                  defaultValue={websiteOwnershipOther}
                  multiline
                  inputProps={{
                    maxLength: 250,
                  }}
                  error={!!errors.websiteOwnershipOther}
                  helperText={errors.websiteOwnershipOther && errors.websiteOwnershipOther.message}
                  inputRef={register({
                    validate: requiredTextField,
                    pattern: {
                      value: Constants.CYRILLIC_REGEX,
                      message: t('validation.text-field.not-cyrillic'),
                    },
                  })}
                  disabled={isReadOnly}
                />
              </Grid>
            ) : (
              <Grid item sm={6} className={classes.emptyGrid} />
            )}
            <Grid item xs={12} sm={6} className={classes.gridRelative}>
              <Controller
                name='websitePlatformType'
                defaultValue={websitePlatformType || ''}
                rules={{
                  required: {
                    value: true,
                    message: t('validation.text-field.required'),
                  },
                }}
                disabled={isReadOnly}
                render={(props) => (
                  <TextField
                    label={`${t('activity-data-step.platform-type')} *`}
                    value={props.value}
                    select
                    error={!!errors.websitePlatformType}
                    helperText={errors.websitePlatformType && errors.websitePlatformType.message}
                    onChange={(e) => {
                      handleWebsitePlatformTypeChange(e.target.value);
                      props.onChange(e);
                    }}
                    className={classes.selectDropdown}
                    disabled={isReadOnly}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='end'>
                          <InfoOutlinedIcon
                            className={classes.infoIcon}
                            onClick={() =>
                              infoDialogOpen(
                                t('activity-data-step.platform-type.info-dialog'),
                                t('activity-data-step.platform-type')
                              )
                            }
                          />
                        </InputAdornment>
                      ),
                    }}
                    inputProps={{
                      classes: {
                        icon: classes.icon,
                      },
                    }}
                  >
                    {websitePlatformTypes.map((item) => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
                control={control}
              />
            </Grid>
            {isOtherWebsitePlatformTypeSelected ? (
              <Grid item xs={12} sm={6}>
                <TextField
                  label={`${t('activity-data-step.platform-type-other')} *`}
                  name='websitePlatformTypeOther'
                  defaultValue={websitePlatformTypeOther}
                  multiline
                  inputProps={{
                    maxLength: 250,
                  }}
                  error={!!errors.websitePlatformTypeOther}
                  helperText={
                    errors.websitePlatformTypeOther && errors.websitePlatformTypeOther.message
                  }
                  inputRef={register({
                    validate: requiredTextField,
                  })}
                  disabled={isReadOnly}
                />
              </Grid>
            ) : (
              <Grid item sm={6} className={classes.emptyGrid} />
            )}
          </>
        ) : (
          <>
            <Grid item xs={12} sm={6} className={classes.gridRelative}>
              <Controller
                name='objectOwnership'
                defaultValue={objectOwnership || ''}
                rules={{
                  required: {
                    value: true,
                    message: t('validation.text-field.required'),
                  },
                }}
                disabled={isReadOnly}
                render={(props) => (
                  <TextField
                    label={`${t('activity-data-step.object-ownership')} *`}
                    value={props.value}
                    select
                    error={!!errors.objectOwnership}
                    helperText={errors.objectOwnership && errors.objectOwnership.message}
                    onChange={(e) => {
                      handleObjectOwnershipChange(e.target.value);
                      props.onChange(e);
                    }}
                    className={classes.selectDropdown}
                    disabled={isReadOnly}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='end'>
                          <InfoOutlinedIcon
                            className={classes.infoIcon}
                            onClick={() =>
                              infoDialogOpen(
                                t('activity-data-step.object-ownership.info-dialog'),
                                t('activity-data-step.object-ownership')
                              )
                            }
                          />
                        </InputAdornment>
                      ),
                    }}
                    inputProps={{
                      classes: {
                        icon: classes.icon,
                      },
                    }}
                  >
                    {objectOwnerships.map((item) => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
                control={control}
              />
            </Grid>
            {isOtherObjectOwnershipSelected ? (
              <Grid item xs={12} sm={6}>
                <TextField
                  label={`${t('activity-data-step.object-ownership-other')} *`}
                  name='objectOwnershipOther'
                  defaultValue={objectOwnershipOther}
                  multiline
                  inputProps={{
                    maxLength: 250,
                  }}
                  error={!!errors.objectOwnershipOther}
                  helperText={errors.objectOwnershipOther && errors.objectOwnershipOther.message}
                  inputRef={register({
                    validate: requiredTextField,
                    pattern: {
                      value: Constants.CYRILLIC_REGEX,
                      message: t('validation.text-field.not-cyrillic'),
                    },
                  })}
                  disabled={isReadOnly}
                />
              </Grid>
            ) : (
              <Grid item sm={6} className={classes.emptyGrid} />
            )}
            {terminalType !== terminalTypeSoftwarePosId && (
              <>
                <Grid item xs={12} sm={6} className={classes.gridRelative}>
                  <Controller
                    name='posDeviceTypeId'
                    defaultValue={posDeviceTypeId || ''}
                    disabled={isReadOnly}
                    rules={{
                      required: {
                        value: true,
                        message: t('validation.text-field.required'),
                      },
                    }}
                    render={(props) => (
                      <TextField
                        label={t('activity-data-step.pos-device-type')}
                        value={props.value}
                        select
                        error={!!errors.posDeviceTypeId}
                        helperText={errors.posDeviceTypeId && errors.posDeviceTypeId.message}
                        onChange={(e) => {
                          props.onChange(e);
                        }}
                        className={classes.selectDropdown}
                        disabled={isReadOnly}
                      >
                        {posDeviceTypes.map((item) => (
                          <MenuItem key={item.id} value={item.id}>
                            {item.name}
                          </MenuItem>
                        ))}
                      </TextField>
                    )}
                    control={control}
                  />
                </Grid>
                <Grid item sm={6} className={classes.emptyGrid} />
              </>
            )}
            <Grid item xs={12} sm={6}>
              <TextField
                label={`${t('activity-data-step.pos-terminals-number')} *`}
                name='posTerminalsCount'
                defaultValue={posTerminalsCount}
                multiline
                inputProps={{
                  maxLength: 1000,
                }}
                error={!!errors.posTerminalsCount}
                helperText={errors.posTerminalsCount && errors.posTerminalsCount.message}
                inputRef={register({
                  validate: requiredTextField,
                  pattern: {
                    value: Constants.CYRILLIC_REGEX,
                    message: t('validation.text-field.not-cyrillic'),
                  },
                })}
                disabled={isReadOnly}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <InfoOutlinedIcon
                        className={classes.infoIcon}
                        onClick={() =>
                          infoDialogOpen(
                            t('activity-data-step.pos-terminals-number-info-dialog'),
                            t('activity-data-step.pos-terminals-number')
                          )
                        }
                      />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </>
        )}
        <Grid item xs={12} className={classes.activityField}>
          <TextField
            label={`${t('activity-data-step.activity')} *`}
            name='activity'
            disabled={isActivityFromRegix || isReadOnly}
            defaultValue={activity}
            multiline
            inputProps={{
              maxLength: 4000,
            }}
            error={!!errors.activity}
            helperText={errors.activity && errors.activity.message}
            inputRef={register({
              validate: requiredTextField,
              pattern: {
                value: !isActivityFromRegix ? Constants.CYRILLIC_REGEX : null,
                message: t('validation.text-field.not-cyrillic'),
              },
            })}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label={`${t('activity-data-step.main-activity-classification')} *`}
            name='mainActivityClassification'
            disabled={isMainActivityClassificationFromRegix || isReadOnly}
            defaultValue={mainActivityClassification}
            multiline
            inputProps={{
              maxLength: 250,
            }}
            error={!!errors.mainActivityClassification}
            helperText={
              errors.mainActivityClassification && errors.mainActivityClassification.message
            }
            inputRef={register({
              validate: requiredTextField,
              pattern: {
                value: !isMainActivityClassificationFromRegix ? Constants.CYRILLIC_REGEX : null,
                message: t('validation.text-field.not-cyrillic'),
              },
            })}
          />
        </Grid>
        <Grid item xs={12} sm={6} className={classes.gridRelative}>
          <TextField
            label={`${t('activity-data-step.commercial-pos-activity')} *`}
            name='commercialPOSActivity'
            defaultValue={commercialPOSActivity}
            multiline
            inputProps={{
              maxLength: 250,
            }}
            error={!!errors.commercialPOSActivity}
            helperText={errors.commercialPOSActivity && errors.commercialPOSActivity.message}
            inputRef={register({
              validate: requiredTextField,
              pattern: {
                value: Constants.CYRILLIC_REGEX,
                message: t('validation.text-field.not-cyrillic'),
              },
            })}
            disabled={isReadOnly}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <InfoOutlinedIcon
                    className={classes.infoIcon}
                    onClick={() =>
                      infoDialogOpen(
                        t('activity-data-step.commercial-pos-activity.info-dialog', {
                          posType:
                            terminalType === terminalTypeVirtualPosId ? t('virtual-pos') : t('pos'),
                        }),
                        t('activity-data-step.commercial-pos-activity')
                      )
                    }
                  />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <span>{`${t('activity-data-step.object-address-matches-registration-address')} *`}</span>
          <RadioGroup
            name='controlled-radio-buttons-group'
            value={isObjectAddressSameAsRegistrationAddress}
            row
          >
            <FormControlLabel
              inputRef={register({
                required: {
                  value: true,
                  message: t('validation.text-field.required'),
                },
              })}
              name='isObjectAddressSameAsRegistrationAddress'
              className={classes.radioButton}
              value
              onClick={handleChange}
              control={<Radio />}
              label={t('general.yes')}
              disabled={isReadOnly}
            />
            <FormControlLabel
              inputRef={register({
                required: {
                  value: true,
                  message: t('validation.text-field.required'),
                },
              })}
              name='isObjectAddressSameAsRegistrationAddress'
              className={classes.radioButton}
              value={false}
              onClick={handleChange}
              control={<Radio />}
              label={t('general.no')}
              disabled={isReadOnly}
            />
          </RadioGroup>
          {errors.isObjectAddressSameAsRegistrationAddress && (
            <p className='radioButtonsErrorMessage'>
              {errors.isObjectAddressSameAsRegistrationAddress.message}
            </p>
          )}
        </Grid>
        {isObjectAddressSameAsRegistrationAddress === false && (
          <div className={classes.subsectionAddres} spacing={3}>
            <Grid item xs={12} sm={6} className={classes.subsectionLabel}>
              {t('activity-data-step.address')}
              <span className={classes.subsectionInfoIcon}>
                <InfoOutlinedIcon
                  fontSize='inherit'
                  onClick={() =>
                    infoDialogOpen(
                      t('activity-data-step.address.info-dialog'),
                      t('activity-data-step.address')
                    )
                  }
                />
              </span>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <TextField
                  label={`${t('general.district')} *`}
                  name='objectAddressProvince'
                  defaultValue={objectAddressProvince}
                  multiline
                  inputProps={{
                    maxLength: 40,
                  }}
                  error={!!errors.objectAddressProvince}
                  helperText={errors.objectAddressProvince && errors.objectAddressProvince.message}
                  inputRef={register({
                    validate: requiredTextField,
                    pattern: {
                      value: Constants.CYRILLIC_REGEX,
                      message: t('validation.text-field.not-cyrillic'),
                    },
                  })}
                  disabled={isReadOnly}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label={`${t('general.municipality')} *`}
                  name='objectAddressMunicipality'
                  defaultValue={objectAddressMunicipality}
                  multiline
                  inputProps={{
                    maxLength: 40,
                  }}
                  error={!!errors.objectAddressMunicipality}
                  helperText={
                    errors.objectAddressMunicipality && errors.objectAddressMunicipality.message
                  }
                  inputRef={register({
                    validate: requiredTextField,
                    pattern: {
                      value: Constants.CYRILLIC_REGEX,
                      message: t('validation.text-field.not-cyrillic'),
                    },
                  })}
                  disabled={isReadOnly}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label={`${t('general.territorial-unit')} *`}
                  name='objectAddressSettlement'
                  defaultValue={objectAddressSettlement}
                  multiline
                  inputProps={{
                    maxLength: 40,
                  }}
                  error={!!errors.objectAddressSettlement}
                  helperText={
                    errors.objectAddressSettlement && errors.objectAddressSettlement.message
                  }
                  inputRef={register({
                    validate: requiredTextField,
                    pattern: {
                      value: Constants.CYRILLIC_REGEX,
                      message: t('validation.text-field.not-cyrillic'),
                    },
                  })}
                  disabled={isReadOnly}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label={`${t('general.address')} *`}
                  name='objectAddressLocation'
                  defaultValue={objectAddressLocation}
                  multiline
                  inputProps={{
                    maxLength: 40,
                  }}
                  error={!!errors.objectAddressLocation}
                  helperText={errors.objectAddressLocation && errors.objectAddressLocation.message}
                  inputRef={register({
                    validate: requiredTextField,
                    pattern: {
                      value: Constants.CYRILLIC_REGEX,
                      message: t('validation.text-field.not-cyrillic'),
                    },
                  })}
                  disabled={isReadOnly}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label={`${t('general.post-code')} *`}
                  name='objectAddressPostCode'
                  defaultValue={objectAddressPostCode}
                  inputProps={{
                    maxLength: 4,
                  }}
                  error={!!errors.objectAddressPostCode}
                  helperText={errors.objectAddressPostCode && errors.objectAddressPostCode.message}
                  inputRef={register({
                    validate: requiredTextField,
                    pattern: {
                      value: /^[1-9][0-9]{3}$/,
                      message: t('validation.invalid-bg-post-code', { count: '4' }),
                    },
                  })}
                  disabled={isReadOnly}
                />
              </Grid>
            </Grid>
          </div>
        )}
        {terminalType !== terminalTypeVirtualPosId && (
          <>
            <Grid item xs={12}>
              <span>{`${t('activity-data-step.is-facility-seasonal')} *`}</span>
              <RadioGroup name='controlled-radio-buttons-group' value={isSeasonalObject} row>
                <FormControlLabel
                  inputRef={register({
                    required: {
                      value: true,
                      message: t('validation.text-field.required'),
                    },
                  })}
                  name='isSeasonalObject'
                  className={classes.radioButton}
                  value
                  onClick={handleChange}
                  control={<Radio />}
                  label={t('general.yes')}
                  disabled={isReadOnly}
                />
                <FormControlLabel
                  inputRef={register({
                    required: {
                      value: true,
                      message: t('validation.text-field.required'),
                    },
                  })}
                  name='isSeasonalObject'
                  className={classes.radioButton}
                  value={false}
                  onClick={handleChange}
                  control={<Radio />}
                  label={t('general.no')}
                  disabled={isReadOnly}
                />
              </RadioGroup>
              {errors.isSeasonalObject && (
                <p className='radioButtonsErrorMessage'>{errors.isSeasonalObject.message}</p>
              )}
            </Grid>
            {isSeasonalObject && (
              <>
                <Grid item xs={12} sm={6} className={classes.gridRelative}>
                  <Controller
                    name='seasonalityId'
                    defaultValue={seasonalityId || ''}
                    rules={{
                      required: {
                        value: true,
                        message: t('validation.text-field.required'),
                      },
                    }}
                    disabled={isReadOnly}
                    render={(props) => (
                      <TextField
                        label={`${t('activity-data-step.seasonality')} *`}
                        value={props.value}
                        select
                        error={!!errors.seasonalityId}
                        helperText={errors.seasonalityId && errors.seasonalityId.message}
                        onChange={(e) => {
                          handleSeasonalityChange(e.target.value);
                          props.onChange(e);
                        }}
                        className={classes.selectDropdown}
                        disabled={isReadOnly}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position='end'>
                              <InfoOutlinedIcon
                                className={classes.infoIcon}
                                onClick={() =>
                                  infoDialogOpen(
                                    t('activity-data-step.seasonality-info-dialog'),
                                    t('activity-data-step.seasonality')
                                  )
                                }
                              />
                            </InputAdornment>
                          ),
                        }}
                        inputProps={{
                          classes: {
                            icon: classes.icon,
                          },
                        }}
                      >
                        {seasonalities.map((item) => (
                          <MenuItem key={item.id} value={item.id}>
                            {item.name}
                          </MenuItem>
                        ))}
                      </TextField>
                    )}
                    control={control}
                  />
                </Grid>
                {isTemporaryUsageSelected ? (
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label={`${t('activity-data-step.temporary-usage-comment')} *`}
                      name='seasonalityComment'
                      defaultValue={seasonalityComment}
                      multiline
                      inputProps={{
                        maxLength: 1000,
                      }}
                      error={!!errors.seasonalityComment}
                      helperText={errors.seasonalityComment && errors.seasonalityComment.message}
                      inputRef={register({
                        validate: requiredTextField,
                        pattern: {
                          value: Constants.CYRILLIC_REGEX,
                          message: t('validation.text-field.not-cyrillic'),
                        },
                      })}
                      disabled={isReadOnly}
                    />
                  </Grid>
                ) : (
                  <Grid item sm={6} className={classes.emptyGrid} />
                )}
              </>
            )}
          </>
        )}
      </Grid>
      {productsData.map((productData, index) => (
        <ProductData
          key={`productData${productData.identifier}`}
          index={index}
          productData={productData}
          errors={errors}
          control={control}
          register={register}
          removeButtonVisible={productsData.length > 1}
          handleRemoveProduct={handleRemoveProductData}
          handleChangeProduct={handleChangeProductData}
          addButtonVisible={
            productsData.length - 1 === index && productsData.length < maxActivityDataProducts
          }
          handleAddProduct={handleAddProductData}
          infoDialogOpen={infoDialogOpen}
        />
      ))}
      <div className={classes.subsection}>
        <Grid item xs={12} sm={6} className={classes.subsectionLabel}>
          {t('general.additional-information')}
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} className={classes.gridRelative}>
            <TextField
              disabled={isReadOnly}
              label={t('activity-data-step.average-order-price')}
              name='averageOrderPrice'
              defaultValue={averageOrderPrice}
              inputProps={{
                maxLength: 11,
              }}
              error={!!errors.averageOrderPrice}
              helperText={errors.averageOrderPrice && errors.averageOrderPrice.message}
              inputRef={register({
                pattern: {
                  value: /^(0([.,][0-9]{1,2})?|[1-9][0-9]{0,7}([.,][0-9]{1,2})?)$/,
                  message: t('validation.decimal.invalid'),
                },
              })}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <InfoOutlinedIcon
                      className={classes.infoIcon}
                      onClick={() =>
                        infoDialogOpen(
                          terminalType === terminalTypeVirtualPosId
                            ? t('activity-data-step.virtual-pos-average-order-price.info-dialog')
                            : t('activity-data-step.average-order-price.info-dialog'),
                          t('activity-data-step.average-order-price')
                        )
                      }
                    />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} className={classes.gridRelative}>
            <TextField
              disabled={isReadOnly}
              label={t('activity-data-step.last-year-turnover')}
              name='previousYearTurnover'
              defaultValue={previousYearTurnover}
              inputProps={{
                maxLength: 11,
              }}
              error={!!errors.previousYearTurnover}
              helperText={errors.previousYearTurnover && errors.previousYearTurnover.message}
              inputRef={register({
                pattern: {
                  value: /^(0([.,][0-9]{1,2})?|[1-9][0-9]{0,7}([.,][0-9]{1,2})?)$/,
                  message: t('validation.decimal.invalid'),
                },
              })}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <InfoOutlinedIcon
                      className={classes.infoIcon}
                      onClick={() =>
                        infoDialogOpen(
                          terminalType === terminalTypeVirtualPosId
                            ? t('activity-data-step.virtual-pos-last-year-turnover.info-dialog')
                            : t('activity-data-step.last-year-turnover.info-dialog'),
                          t('activity-data-step.last-year-turnover')
                        )
                      }
                    />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} className={classes.gridRelative}>
            <TextField
              disabled={isReadOnly}
              label={t('activity-data-step.average-monthly-pos-turnover')}
              name='averageMonthlyPOSTurnover'
              defaultValue={averageMonthlyPOSTurnover}
              inputProps={{
                maxLength: 11,
              }}
              error={!!errors.averageMonthlyPOSTurnover}
              helperText={
                errors.averageMonthlyPOSTurnover && errors.averageMonthlyPOSTurnover.message
              }
              inputRef={register({
                pattern: {
                  value: /^(0([.,][0-9]{1,2})?|[1-9][0-9]{0,7}([.,][0-9]{1,2})?)$/,
                  message: t('validation.decimal.invalid'),
                },
              })}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <InfoOutlinedIcon
                      className={classes.infoIcon}
                      onClick={() =>
                        infoDialogOpen(
                          terminalType === terminalTypeVirtualPosId
                            ? t(
                                'activity-data-step.virtual-pos-average-monthly-pos-turnover.info-dialog'
                              )
                            : t('activity-data-step.average-monthly-pos-turnover.info-dialog'),
                          t('activity-data-step.average-monthly-pos-turnover')
                        )
                      }
                    />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} className={classes.gridRelative}>
            <TextField
              label={t('activity-data-step.average-monthly-transactions')}
              name='averageMonthlyTransactions'
              defaultValue={averageMonthlyTransactions}
              inputProps={{
                maxLength: 8,
              }}
              error={!!errors.averageMonthlyTransactions}
              helperText={
                errors.averageMonthlyTransactions && errors.averageMonthlyTransactions.message
              }
              inputRef={register({
                pattern: {
                  value: /^[1-9]\d*$/,
                  message: t('validation.text-field.invalid'),
                },
              })}
              disabled={isReadOnly}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <InfoOutlinedIcon
                      className={classes.infoIcon}
                      onClick={() =>
                        infoDialogOpen(
                          terminalType === terminalTypeVirtualPosId
                            ? t(
                                'activity-data-step.virtual-pos-average-monthly-transactions.info-dialog'
                              )
                            : t('activity-data-step.average-monthly-transactions.info-dialog'),
                          t('activity-data-step.average-monthly-transactions')
                        )
                      }
                    />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
      </div>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12}>
          <TextField
            label={t('activity-data-step.comment')}
            name='activityComment'
            defaultValue={activityComment}
            multiline
            inputProps={{
              maxLength: 1000,
            }}
            error={!!errors.activityComment}
            helperText={errors.activityComment && errors.activityComment.message}
            inputRef={register({
              pattern: {
                value: Constants.CYRILLIC_REGEX,
                message: t('validation.text-field.not-cyrillic'),
              },
            })}
            disabled={isReadOnly}
          />
        </Grid>
      </Grid>
      <InfoDialog open={showInfoDialog} title={infoDialogTitle} handleClose={infoDialogClose}>
        {infoDialogContent}
      </InfoDialog>
    </form>
  );
};

export default ActivityDataStep;
