import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import useStyles from './request-info.styles';
import SadFaceIcon from '../../../assets/images/sad-face-red.svg';
import HappyFaceIcon from '../../../assets/images/happy-face-green.svg';
import InfoIcon from '../../../assets/images/info_icon.svg';
import { useApplicationState } from '../../../providers/application/application.provider';
import { signOut } from '../../../providers/application/application.actions';

const RequestInfo = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();

  const {
    requestCannotContinue,
    confirmationEmailPending,
    emailConfirmed,
    pendingPasswordReset,
    personalIdentifierEntered,
    existsActiveRequest,
  } = useApplicationState();

  const routeChange = () => {
    const path = '/Login';
    history.push(path);
  };

  let infoTitle = '';
  let infoSubtitle = '';
  let loginButton = '';

  useEffect(() => {
    if (emailConfirmed && requestCannotContinue) {
      signOut();
    }
  }, [emailConfirmed, requestCannotContinue]);

  if (existsActiveRequest) {
    infoTitle = t('error.error-title');
    infoSubtitle = <div>{t('error.active-request-message-subtitle')}</div>;
    loginButton = (
      <Button
        type='submit'
        variant='contained'
        onClick={routeChange}
        color='primary'
        className={classes.button}
      >
        {t('general.login')}
      </Button>
    );
  }
  if (requestCannotContinue) {
    infoTitle = t('request-info.title.request-cannot-continue');
    infoSubtitle = <div>{t('request-info.title.request-cannot-continue.subtitle')}</div>;
  }
  if (personalIdentifierEntered) {
    infoTitle = t('request-info.title.request-cannot-continue');
    infoSubtitle = <div>{t('request-info.subtitle.registration-with-personal-identifier')}</div>;
  } else if (confirmationEmailPending) {
    infoTitle = t('request-info.title.confirmation-email-pending');

    infoSubtitle = (
      <div>
        {t('request-info.subtitle.confirmation-email-pending.line1')}
        <br />
        {t('request-info.subtitle.confirmation-email-pending.line2')}
      </div>
    );
  } else if (pendingPasswordReset) {
    infoTitle = t('request-info.title.pending-password-reset');

    infoSubtitle = (
      <div>
        {t('request-info.subtitle.pending-password-reset.line1')}
        <br />
        {t('request-info.subtitle.pending-password-reset.line2')}
      </div>
    );
  }

  return (
    <Grid container spacing={2} justifyContent='center'>
      <Grid className={classes.gridItem} item xs={12}>
        {existsActiveRequest && <img src={HappyFaceIcon} alt='' />}
        {personalIdentifierEntered && <img src={SadFaceIcon} alt='' />}
        {requestCannotContinue && <img src={InfoIcon} alt='' />}
      </Grid>
      <Grid className={classes.gridItem} item xs={12}>
        <Typography component='h2' variant='h2'>
          {infoTitle}
        </Typography>
      </Grid>
      <Grid className={classes.gridItem} item xs={12}>
        <Typography component='h6' variant='h6'>
          {infoSubtitle}
        </Typography>
      </Grid>
      {existsActiveRequest && (
        <Grid className={classes.gridItem} item xs={12}>
          {loginButton}
        </Grid>
      )}
    </Grid>
  );
};

export default RequestInfo;
