/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import { useTranslation } from 'react-i18next';
import { Controller } from 'react-hook-form';
import MenuItem from '@mui/material/MenuItem';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Button from '@mui/material/Button';
import { requiredTextField } from '../../../utils/validation.utils';
import useStyles from './product-data.styles';
import { useApplicationState } from '../../../providers/application/application.provider';
import Constants from '../../../utils/constants';

const ProductData = (p) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const {
    isReadOnly,
    nomenclatures: {
      licenses,
      productTypes,
      ownProductTypes,
      licenseOtherId,
      ownProductTypeOtherId,
      productTypeOwnId,
    },
  } = useApplicationState();

  const {
    errors,
    control,
    register,
    removeButtonVisible,
    handleRemoveProduct,
    handleChangeProduct,
    addButtonVisible,
    handleAddProduct,
    infoDialogOpen,
    index,
    productData: {
      identifier,
      productIdentifier,
      productGroups,
      license,
      licenseOther,
      productType,
      ownProductType,
      ownProductTypeOther,
    },
  } = p;

  const [isProductTypeOwnSelected, setIsProductTypeOwnSelected] = React.useState(
    productType === productTypeOwnId
  );

  const [isOwnProductTypeOtherSelected, setIsOwnProductTypeOtherSelected] = React.useState(
    ownProductType === ownProductTypeOtherId
  );

  const handleProductTypeChange = (selectedValue) => {
    setIsOwnProductTypeOtherSelected(ownProductType === ownProductTypeOtherId);
    setIsProductTypeOwnSelected(selectedValue === productTypeOwnId);
    handleChangeProduct({ ownProductType: null, ownProductTypeOther: '' });
  };

  const handleOwnProductTypeChange = (selectedValue) => {
    setIsOwnProductTypeOtherSelected(selectedValue === ownProductTypeOtherId);
    handleChangeProduct({ ownProductTypeOther: '' });
  };

  const [isOtherLicenseSelected, setIsLicenseOtherSelected] = React.useState(
    license === licenseOtherId
  );

  const handleLicenseChange = (selectedValue) => {
    setIsLicenseOtherSelected(selectedValue === licenseOtherId);
    handleChangeProduct({ licenseOther: '' });
  };

  return (
    <div className={classes.subsection}>
      <Grid item xs={12} sm={6} className={classes.subsectionLabel}>
        {t('activity-data-step.products')}
        <span className={classes.subsectionInfoIcon}>
          <InfoOutlinedIcon
            fontSize='inherit'
            onClick={() =>
              infoDialogOpen(
                t('activity-data-step.product-groups.info-dialog'),
                t('activity-data-step.products')
              )
            }
          />
        </span>
        {removeButtonVisible && !isReadOnly && (
          <Button className={classes.removeBtn} onClick={() => handleRemoveProduct(identifier)}>
            {t('general.delete-product-data')}
          </Button>
        )}
      </Grid>
      <Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <input
              type='hidden'
              name={`products[${index}].productIdentifier`}
              defaultValue={productIdentifier}
            />
            <TextField
              label={`${t('activity-data-step.product-groups')} *`}
              name={`products[${index}].productGroups`}
              defaultValue={productGroups}
              multiline
              inputProps={{
                maxLength: 250,
              }}
              error={
                !!errors.products &&
                !!errors.products[`${index}`] &&
                !!errors.products[`${index}`].productGroups
              }
              helperText={
                errors.products &&
                errors.products[`${index}`] &&
                errors.products[`${index}`].productGroups &&
                errors.products[`${index}`].productGroups.message
              }
              inputRef={register({
                validate: requiredTextField,
                pattern: {
                  value: Constants.CYRILLIC_REGEX,
                  message: t('validation.text-field.not-cyrillic'),
                },
              })}
              disabled={isReadOnly}
            />
          </Grid>
          <Grid item sm={6} className={classes.emptyGrid} />
          <Grid item xs={12} sm={6}>
            <Controller
              name={`products[${index}].license`}
              defaultValue={license || ''}
              rules={{
                required: {
                  value: true,
                  message: t('validation.text-field.required'),
                },
              }}
              render={(props) => (
                <TextField
                  label={`${t('activity-data-step.licenses')} *`}
                  value={props.value}
                  select
                  error={
                    !!errors.products &&
                    !!errors.products[`${index}`] &&
                    !!errors.products[`${index}`].license
                  }
                  helperText={
                    errors.products &&
                    errors.products[`${index}`] &&
                    errors.products[`${index}`].license &&
                    errors.products[`${index}`].license.message
                  }
                  onChange={(e) => {
                    handleLicenseChange(e.target.value);
                    props.onChange(e);
                  }}
                  disabled={isReadOnly}
                >
                  {licenses.map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
                </TextField>
              )}
              control={control}
            />
          </Grid>
          {isOtherLicenseSelected ? (
            <Grid item xs={12} sm={6}>
              <TextField
                label={`${t('activity-data-step.other-licenses-label')} *`}
                name={`products[${index}].licenseOther`}
                defaultValue={licenseOther}
                multiline
                inputProps={{
                  maxLength: 250,
                }}
                error={
                  !!errors.products &&
                  !!errors.products[`${index}`] &&
                  !!errors.products[`${index}`].licenseOther
                }
                helperText={
                  errors.products &&
                  errors.products[`${index}`] &&
                  errors.products[`${index}`].licenseOther &&
                  errors.products[`${index}`].licenseOther.message
                }
                inputRef={register({
                  validate: requiredTextField,
                  pattern: {
                    value: Constants.CYRILLIC_REGEX,
                    message: t('validation.text-field.not-cyrillic'),
                  },
                })}
                disabled={isReadOnly}
              />
            </Grid>
          ) : (
            <Grid item sm={6} className={classes.emptyGrid} />
          )}
          <Grid item xs={12} sm={6}>
            <Controller
              name={`products[${index}].productType`}
              defaultValue={productType || ''}
              rules={{
                required: {
                  value: true,
                  message: t('validation.text-field.required'),
                },
              }}
              render={(props) => (
                <TextField
                  label={`${t('activity-data-step.product-types')} *`}
                  value={props.value}
                  select
                  error={
                    !!errors.products &&
                    !!errors.products[`${index}`] &&
                    !!errors.products[`${index}`].productType
                  }
                  helperText={
                    errors.products &&
                    errors.products[`${index}`] &&
                    errors.products[`${index}`].productType &&
                    errors.products[`${index}`].productType.message
                  }
                  onChange={(e) => {
                    handleProductTypeChange(e.target.value);
                    props.onChange(e);
                  }}
                  disabled={isReadOnly}
                >
                  {productTypes.map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
                </TextField>
              )}
              control={control}
            />
          </Grid>
          {isProductTypeOwnSelected ? (
            <Grid item xs={12} sm={6}>
              <Controller
                name={`products[${index}].ownProductType`}
                defaultValue={ownProductType || ''}
                rules={{
                  required: {
                    value: true,
                    message: t('validation.text-field.required'),
                  },
                }}
                render={(props) => (
                  <TextField
                    label={`${t('activity-data-step.own-product-types')} *`}
                    value={props.value}
                    select
                    error={
                      !!errors.products &&
                      !!errors.products[`${index}`] &&
                      !!errors.products[`${index}`].ownProductType
                    }
                    helperText={
                      errors.products &&
                      errors.products[`${index}`] &&
                      errors.products[`${index}`].ownProductType &&
                      errors.products[`${index}`].ownProductType.message
                    }
                    onChange={(e) => {
                      handleOwnProductTypeChange(e.target.value);
                      props.onChange(e);
                    }}
                    disabled={isReadOnly}
                  >
                    {ownProductTypes.map((item) => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
                control={control}
              />
            </Grid>
          ) : (
            <Grid item sm={6} className={classes.emptyGrid} />
          )}
          {isProductTypeOwnSelected && isOwnProductTypeOtherSelected && (
            <>
              <Grid item xs={12} sm={6}>
                <TextField
                  label={`${t('activity-data-step.own-product-type-other')} *`}
                  name={`products[${index}].ownProductTypeOther`}
                  defaultValue={ownProductTypeOther}
                  multiline
                  inputProps={{
                    maxLength: 250,
                  }}
                  error={
                    !!errors.products &&
                    !!errors.products[`${index}`] &&
                    !!errors.products[`${index}`].ownProductTypeOther
                  }
                  helperText={
                    errors.products &&
                    errors.products[`${index}`] &&
                    errors.products[`${index}`].ownProductTypeOther &&
                    errors.products[`${index}`].ownProductTypeOther.message
                  }
                  inputRef={register({
                    validate: requiredTextField,
                    pattern: {
                      value: Constants.CYRILLIC_REGEX,
                      message: t('validation.text-field.not-cyrillic'),
                    },
                  })}
                  disabled={isReadOnly}
                />
              </Grid>
              <Grid item sm={6} className={classes.emptyGrid} />
            </>
          )}
          {addButtonVisible && !isReadOnly && (
            <Button className={classes.addBtn} onClick={() => handleAddProduct()}>
              {t('product-data.add-product-data')}
            </Button>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default ProductData;
