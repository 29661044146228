import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  stepContentInner: {
    marginBottom: '0px',
    marginTop: theme.spacing(2),
  },
  stepContent: {
    marginTop: '8px',
    borderLeft: '1px solid #bdbdbd',
    marginLeft: '12px',
    paddingLeft: '20px',
    paddingRight: '8px',
  },
  root: {
    width: '100%',
    marginTop: theme.spacing(2),
  },
  actionsContainer: {
    textAlign: 'right',
    [theme.breakpoints.down('md')]: {
      textAlign: 'center',
    },
  },
  button: {
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
    float: 'right',
  },
  link: {
    float: 'left',
    paddingLeft: '5px',
    paddingTop: '12px',
  },
  requestLink: {
    paddingLeft: '5px',
    marginTop: '0px',
    marginBottom: '0px',
    [theme.breakpoints.down('md')]: {
      marginTop: '12px',
      marginBottom: '10px',
      paddingLeft: '15px',
    },
  },
  stepper: {
    [theme.breakpoints.down('md')]: {
      padding: '8px 8px 8px 8px',
    },
  },
}));

export default useStyles;
