/* eslint-disable react/jsx-no-duplicate-props */
import React, { useEffect, useImperativeHandle } from 'react';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import { useTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';
import MenuItem from '@mui/material/MenuItem';
import InputAdornment from '@mui/material/InputAdornment';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import InfoDialog from '../../info-dialog/info-dialog.component';
import {
  useApplicationState,
  useApplicationDispatch,
} from '../../../providers/application/application.provider';
import useStyles from './applicant-data-step.syles';
import {
  saveApplicantDataAsync,
  loadApplicantDataAsync,
  setIsOtherApplicantSelected,
  clearApplicantForm,
} from '../../../providers/application/application.actions';
import PhoneNumber from '../phone-number/phone-number.component';
import {
  isValidEGN,
  requiredTextField,
  removeCharacterFromNumber,
} from '../../../utils/validation.utils';
import Constants from '../../../utils/constants';

const ApplicantDataStep = ({ formRef, backRef, currentStepId }) => {
  const { handleSubmit, errors, control, register, getValues } = useForm();
  const { t } = useTranslation();
  const classes = useStyles();

  const {
    applicantType,
    applicantIdentifier,
    applicantNames,
    applicantEmail,
    applicantMobilePhoneCountry,
    applicantMobilePhoneNumber,
    applicantMobilePhoneConfirmed,
    applicants,
    applicantId,
    isApplicantNameOtherSelected,
    isReadOnly,
    nomenclatures: { agentApplicantType, mobilePhoneCodes, countryBulgaria },
  } = useApplicationState();
  const dispatch = useApplicationDispatch();

  const [isBulgariaCountryCodeSelected, setIsBulgariaCountryCodeSelected] = React.useState(
    applicantMobilePhoneCountry === countryBulgaria
  );

  const [showInfoDialog, setShowInfoDialog] = React.useState(false);
  const [infoDialogContent, setInfoDialogContent] = React.useState('');
  const [infoDialogTitle, setInfoDialogTitle] = React.useState('');

  const infoDialogOpen = (text, title) => {
    setInfoDialogContent(text);
    setInfoDialogTitle(title);
    setShowInfoDialog(true);
  };

  const infoDialogClose = () => {
    setShowInfoDialog(false);
  };

  const [isApplicantFromRegix] = React.useState(applicantType !== agentApplicantType);

  const handleCountryCodeChange = (code) => {
    setIsBulgariaCountryCodeSelected(code === countryBulgaria);
  };

  const handleApplicantChange = (id) => {
    dispatch(setIsOtherApplicantSelected(id === 0));
  };

  const onChangeApplicantName = () => {
    dispatch(clearApplicantForm());
  };

  if (isApplicantFromRegix) {
    useEffect(() => {
      loadApplicantDataAsync(applicantType, dispatch);
      handleApplicantChange(applicantId);
    }, []);
  }

  const onSubmit = () => {
    const data = getValues();
    const applicantPhoneNumber = isBulgariaCountryCodeSelected
      ? data.applicantMobilePhoneNumber.replace(/^0+/, '')
      : data.applicantMobilePhoneNumber;

    saveApplicantDataAsync(
      {
        ...data,
        applicantType,
        applicantMobilePhoneNumber: applicantPhoneNumber,
        currentStepId,
        isApplicantNameOtherSelected,
      },
      dispatch
    );
  };

  useImperativeHandle(backRef, () => ({
    getValues() {
      return getValues();
    },
  }));

  return (
    <form ref={formRef} onSubmit={handleSubmit(onSubmit)} noValidate>
      <Grid container spacing={3}>
        {isApplicantFromRegix && (
          <Grid item xs={12} sm={6}>
            <Controller
              name='applicantId'
              defaultValue={applicantId || applicantId === 0 ? applicantId : ''}
              disabled={isReadOnly}
              rules={{
                required: {
                  value: true,
                  message: t('validation.text-field.required'),
                },
              }}
              render={(props) => (
                <TextField
                  label={`${t('owner')} *`}
                  value={props.value}
                  select
                  error={!!errors.applicantId}
                  helperText={errors.applicantId && errors.applicantId.message}
                  onChange={(e) => {
                    handleApplicantChange(e.target.value);
                    props.onChange(e);
                    onChangeApplicantName();
                  }}
                  disabled={isReadOnly}
                >
                  {applicants.map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
                </TextField>
              )}
              control={control}
            />
          </Grid>
        )}
        {(!isApplicantFromRegix || isApplicantNameOtherSelected) && (
          <>
            <Grid item xs={12} sm={6}>
              <TextField
                autoFocus
                label={`${t('contact-person-step.names')} *`}
                name='applicantNames'
                defaultValue={applicantNames}
                inputProps={{
                  maxLength: 150,
                }}
                error={!!errors.applicantNames}
                helperText={errors.applicantNames && errors.applicantNames.message}
                inputRef={register({
                  validate: requiredTextField,
                  pattern: {
                    value: Constants.CYRILLIC_REGEX,
                    message: t('validation.text-field.not-cyrillic'),
                  },
                })}
                disabled={isReadOnly}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <InfoOutlinedIcon
                        style={{ display: !isApplicantFromRegix ? 'block' : 'none' }}
                        className={classes.infoIcon}
                        onClick={() =>
                          infoDialogOpen(
                            t('applicant-data-step.names.info-dialog'),
                            t('contact-person-step.names')
                          )
                        }
                      />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            {!isApplicantFromRegix && <Grid item xs={12} sm={6} />}
            <Grid item xs={12} sm={6}>
              <TextField
                label={`${t('applicant-data-step.applicant-identifier')} *`}
                name='applicantIdentifier'
                defaultValue={applicantIdentifier}
                inputProps={{
                  maxLength: 10,
                }}
                error={!!errors.applicantIdentifier}
                helperText={errors.applicantIdentifier && errors.applicantIdentifier.message}
                inputRef={register({
                  validate: {
                    requiredTextField,
                    validEGN: (value) => isValidEGN(value) || t('validation.text-field.invalid'),
                  },
                })}
                disabled={isReadOnly}
              />
            </Grid>
          </>
        )}
        <Grid item xs={12} sm={6}>
          <TextField
            label={`${t('email-address')} *`}
            name='applicantEmail'
            type='email'
            defaultValue={applicantEmail}
            inputProps={{
              maxLength: 40,
            }}
            error={!!errors.applicantEmail}
            helperText={errors.applicantEmail && errors.applicantEmail.message}
            inputRef={register({
              validate: requiredTextField,
              pattern: {
                value: /^[a-zA-Z0-9._%+-]+@(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,63}$/,
                message: t('validation.text-field.invalid'),
              },
            })}
            disabled={isReadOnly}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <PhoneNumber
            options={mobilePhoneCodes}
            selectedOption={applicantMobilePhoneCountry}
            selectedOptionOnChange={(code) => handleCountryCodeChange(code)}
            selectorName='applicantMobilePhoneCountry'
            selectorValidation={{
              validate: (value) => !!value || t('validation.mobile-phone-code.required'),
            }}
            defaultValue={applicantMobilePhoneNumber}
            propertyName='applicantMobilePhoneNumber'
            inputProps={{
              maxLength: isBulgariaCountryCodeSelected ? 10 : 14,
            }}
            inputRef={register({
              validate: {
                requiredTextField,
                validPhoneNumber: (value) =>
                  (isBulgariaCountryCodeSelected
                    ? /^[0-9]{9}$/.test(removeCharacterFromNumber(value).replace(/^0+/, ''))
                    : /^[0-9]{1,14}$/.test(removeCharacterFromNumber(value))) ||
                  t('validation.text-field.invalid'),
              },
            })}
            mobilePhoneCountryError={errors.applicantMobilePhoneCountry}
            mobilePhoneNumberError={errors.applicantMobilePhoneNumber}
            control={control}
            disabled={applicantMobilePhoneConfirmed || isReadOnly}
          />
        </Grid>
      </Grid>
      <InfoDialog open={showInfoDialog} title={infoDialogTitle} handleClose={infoDialogClose}>
        {infoDialogContent}
      </InfoDialog>
    </form>
  );
};

export default ApplicantDataStep;
