import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  stepContentInner: {
    marginBottom: '0px',
    marginTop: theme.spacing(2),
  },
  stepContent: {
    marginTop: '8px',
    borderLeft: '1px solid #bdbdbd',
    marginLeft: '12px',
    paddingLeft: '20px',
    paddingRight: '8px',
  },
  root: {
    width: '100%',
  },
  actionsContainer: {
    textAlign: 'right',
    [theme.breakpoints.down('md')]: {
      textAlign: 'center',
    },
  },
  button: {
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
    float: 'right',
  },
  stepper: {
    [theme.breakpoints.down('md')]: {
      padding: '8px 8px 8px 8px',
    },
  },
  buttonOuterWrapper: {
    display: 'flex',
    alignItems: 'center',
    height: '85px',
  },
  buttonInnerWrapper: {
    position: 'relative',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -10,
    marginLeft: -12,
  },
  containerSuccess: {
    display: 'flex',
    color: '#52ae30',
    padding: '6px 8px',
  },
  gridRelative: {
    position: 'relative',
  },
  generateSmsVerificationCodButton: {
    [theme.breakpoints.down('sm')]: {
      padding: '0',
    },
  },
  infoIcon: {
    color: '#52ae30',
    cursor: 'pointer',
  },
}));

export default useStyles;
