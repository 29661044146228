import React, { useImperativeHandle } from 'react';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import FormControlLabel from '@mui/material/FormControlLabel';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import {
  useApplicationState,
  useApplicationDispatch,
} from '../../../providers/application/application.provider';
import {
  saveContactPersonDataAsync,
  changeCondition,
} from '../../../providers/application/application.actions';
import PhoneNumber from '../phone-number/phone-number.component';
import { requiredTextField, removeCharacterFromNumber } from '../../../utils/validation.utils';
import useStyles from './contact-person-step.styles';
import Constants from '../../../utils/constants';

const ContactPersonStep = ({ formRef, backRef, currentStepId }) => {
  const { handleSubmit, errors, control, register, getValues } = useForm();
  const { t } = useTranslation();

  const classes = useStyles();

  const {
    applicantType,
    contactPersonNames,
    contactPersonTitle,
    contactPersonEmail,
    contactMobilePhoneCountry,
    contactMobilePhoneNumber,
    applicantNames,
    applicantMobilePhoneNumber,
    applicantMobilePhoneCountry,
    applicantEmail,
    isContactPersonSameAsApplicantPerson,
    isApplicantNameOtherSelected,
    applicantId,
    applicants,
    isReadOnly,
    nomenclatures: { agentApplicantType, mobilePhoneCodes, countryBulgaria, applicantTypes },
  } = useApplicationState();
  const dispatch = useApplicationDispatch();

  const [isMerchant] = React.useState(applicantType !== agentApplicantType);

  const [isBulgariaCountryCodeSelected, setIsBulgariaCountryCodeSelected] = React.useState(
    contactMobilePhoneCountry === countryBulgaria
  );
  const handleCountryCodeChange = (code) => {
    setIsBulgariaCountryCodeSelected(code === countryBulgaria);
  };

  const handleChange = (e) => {
    dispatch(changeCondition({ [e.target.name]: e.target.value === 'true' }));
  };

  const constructFormData = (data) => {
    return {
      ...data,
      isContactPersonSameAsApplicantPerson:
        isContactPersonSameAsApplicantPerson !== '' ? isContactPersonSameAsApplicantPerson : null,
    };
  };

  useImperativeHandle(backRef, () => ({
    getValues() {
      return constructFormData(getValues());
    },
  }));

  const onSubmit = () => {
    const data = getValues();
    if (isContactPersonSameAsApplicantPerson === true) {
      saveContactPersonDataAsync(
        {
          ...data,
          isContactPersonSameAsApplicantPerson,
          contactPersonNames:
            isMerchant && !isApplicantNameOtherSelected
              ? applicants.find((applicant) => applicant.id === applicantId).name
              : applicantNames,
          contactPersonEmail: applicantEmail,
          contactMobilePhoneCountry: applicantMobilePhoneCountry,
          contactMobilePhoneNumber: applicantMobilePhoneNumber,
          currentStepId,
        },
        dispatch
      );
    } else {
      const phoneNumber = isBulgariaCountryCodeSelected
        ? data.contactMobilePhoneNumber.replace(/^0+/, '')
        : data.contactMobilePhoneNumber;

      saveContactPersonDataAsync(
        {
          ...data,
          contactMobilePhoneNumber: phoneNumber,
          isContactPersonSameAsApplicantPerson,
          currentStepId,
        },
        dispatch
      );
    }
  };

  return (
    <form ref={formRef} onSubmit={handleSubmit(onSubmit)} noValidate>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <span>{t('contact-person-step.is-contact-person-same-as-applicant-person')}&nbsp;*</span>
          <RadioGroup
            name='controlled-radio-buttons-group'
            value={isContactPersonSameAsApplicantPerson}
            row
          >
            <FormControlLabel
              inputRef={register({
                required: {
                  value: true,
                  message: t('validation.text-field.required'),
                },
              })}
              name='isContactPersonSameAsApplicantPerson'
              className={classes.radioButton}
              value
              onClick={handleChange}
              control={<Radio />}
              label={t('general.yes')}
              disabled={isReadOnly}
            />
            <FormControlLabel
              inputRef={register({
                required: {
                  value: true,
                  message: t('validation.text-field.required'),
                },
              })}
              name='isContactPersonSameAsApplicantPerson'
              className={classes.radioButton}
              value={false}
              onClick={handleChange}
              control={<Radio />}
              label={t('general.no')}
              disabled={isReadOnly}
            />
          </RadioGroup>
          {errors.isContactPersonSameAsApplicantPerson && (
            <p className='radioButtonsErrorMessage'>
              {errors.isContactPersonSameAsApplicantPerson.message}
            </p>
          )}
        </Grid>
        {isContactPersonSameAsApplicantPerson === false && (
          <>
            <Grid item xs={12} sm={6}>
              <TextField
                label={`${t('contact-person-step.names')} *`}
                name='contactPersonNames'
                defaultValue={contactPersonNames}
                inputProps={{
                  maxLength: 150,
                }}
                error={!!errors.contactPersonNames}
                helperText={errors.contactPersonNames && errors.contactPersonNames.message}
                inputRef={register({
                  validate: requiredTextField,
                  pattern: {
                    value: Constants.CYRILLIC_REGEX,
                    message: t('validation.text-field.not-cyrillic'),
                  },
                })}
                disabled={isReadOnly}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label={`${t('email-address')} *`}
                name='contactPersonEmail'
                type='email'
                defaultValue={contactPersonEmail}
                inputProps={{
                  maxLength: 40,
                }}
                error={!!errors.contactPersonEmail}
                helperText={errors.contactPersonEmail && errors.contactPersonEmail.message}
                inputRef={register({
                  validate: requiredTextField,
                  pattern: {
                    value: /^[a-zA-Z0-9._%+-]+@(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,63}$/,
                    message: t('validation.text-field.invalid'),
                  },
                })}
                disabled={isReadOnly}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <PhoneNumber
                options={mobilePhoneCodes}
                selectedOption={contactMobilePhoneCountry}
                selectedOptionOnChange={(code) => handleCountryCodeChange(code)}
                selectorName='contactMobilePhoneCountry'
                selectorValidation={{
                  validate: (value) => !!value || t('validation.mobile-phone-code.required'),
                }}
                defaultValue={contactMobilePhoneNumber}
                propertyName='contactMobilePhoneNumber'
                inputProps={{
                  maxLength: isBulgariaCountryCodeSelected ? 10 : 14,
                }}
                inputRef={register({
                  validate: {
                    requiredTextField,
                    validPhoneNumber: (value) => {
                      return (
                        (isBulgariaCountryCodeSelected
                          ? /^[0-9]{9}$/.test(removeCharacterFromNumber(value).replace(/^0+/, ''))
                          : /^[0-9]{1,14}$/.test(removeCharacterFromNumber(value))) ||
                        t('validation.text-field.invalid')
                      );
                    },
                  },
                })}
                mobilePhoneCountryError={errors.contactMobilePhoneCountry}
                mobilePhoneNumberError={errors.contactMobilePhoneNumber}
                control={control}
                disabled={isReadOnly}
              />
            </Grid>
          </>
        )}
        <Grid item xs={12} sm={6}>
          <TextField
            disabled={isReadOnly}
            label={t('contact-person-step.contact-title')}
            name='contactPersonTitle'
            defaultValue={
              contactPersonTitle === null
                ? applicantTypes.find((type) => type.id === applicantType).name
                : contactPersonTitle
            }
            inputProps={{
              maxLength: 40,
            }}
            error={!!errors.contactPersonTitle}
            helperText={errors.contactPersonTitle && errors.contactPersonTitle.message}
            inputRef={register({
              pattern: {
                value: Constants.CYRILLIC_REGEX,
                message: t('validation.text-field.not-cyrillic'),
              },
            })}
          />
        </Grid>
      </Grid>
    </form>
  );
};

export default ContactPersonStep;
