import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Button from '@mui/material/Button';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import CircularProgress from '@mui/material/CircularProgress';
import DoneIcon from '@mui/icons-material/Done';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import InputAdornment from '@mui/material/InputAdornment';
import InfoDialog from '../info-dialog/info-dialog.component';
import {
  saveClientCredentialsAsync,
  generateVerificationCodeAsync,
} from '../../providers/application/application.actions';
import {
  useApplicationState,
  useApplicationDispatch,
} from '../../providers/application/application.provider';
import useStyles from './manage-password.styles';
import { requiredTextField } from '../../utils/validation.utils';
import StepIcon from '../request/step-icon/step-icon.component';
import RequestInfo from '../request/request-info/request-info.component';

function ManagePassword() {
  const classes = useStyles();
  const { register, errors, handleSubmit, watch, getValues } = useForm();
  const { t } = useTranslation();
  const history = useHistory();
  const {
    requestNumber,
    clientIdentifier,
    requestCannotContinue,
    generatedVerificationCode,
    verificationCodeValidityInMinutes,
    wrongSmsVerificationCode,
    maxSmsVerificationCodeAttemptsReached,
    expiredSmsVerificationCode,
    generateSmsVerificationCodeLoading,
    shouldGenerateVerificationCode,
  } = useApplicationState();
  const dispatch = useApplicationDispatch();
  const pass = watch('password', '');

  const [showInfoDialog, setShowInfoDialog] = React.useState(false);
  const [infoDialogContent, setInfoDialogContent] = React.useState('');
  const [infoDialogTitle, setInfoDialogTitle] = React.useState('');

  const infoDialogOpen = (text, title) => {
    setInfoDialogContent(text);
    setInfoDialogTitle(title);
    setShowInfoDialog(true);
  };

  const infoDialogClose = () => {
    setShowInfoDialog(false);
  };

  const [smsVerificationCodeValue, setSmsVerificationCodeValue] = React.useState('');

  const handleUserInput = (e) => {
    setSmsVerificationCodeValue(e.target.value);
  };

  const resetSmsVerificationCode = () => {
    setSmsVerificationCodeValue('');
  };

  const [isSuccessfullyRegenerated, setIsSuccessfullyRegenerated] = React.useState(false);

  const onSubmit = () => {
    const data = getValues();
    saveClientCredentialsAsync({ ...data, requestNumber }, dispatch, () => history.push('/'));
  };

  const generateNewCode = () => {
    generateVerificationCodeAsync(requestNumber, dispatch, (successfullyRegenerated) => {
      setIsSuccessfullyRegenerated(successfullyRegenerated);
      if (successfullyRegenerated) {
        resetSmsVerificationCode();
      }
    });
  };

  useEffect(() => {
    if (shouldGenerateVerificationCode) {
      generateNewCode();
    }
  }, []);

  if (requestCannotContinue) {
    return (
      <div className={classes.root}>
        <RequestInfo />
      </div>
    );
  }

  return (
    <Stepper activeStep={0} orientation='vertical' className={classes.stepper}>
      <Step key={0}>
        <StepLabel StepIconComponent={StepIcon}>{t('manage-password.title')} </StepLabel>
        <StepContent className={classes.stepContent}>
          <div className={classes.stepContentInner}>
            <form onSubmit={handleSubmit(onSubmit)} className={classes.root}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <TextField
                    disabled
                    label={`${t('general.client-businessIdentifier')} *`}
                    name='clientIdentifier'
                    defaultValue={clientIdentifier}
                    inputRef={register}
                  />
                </Grid>
                <Grid item xs={12} className={classes.gridRelative}>
                  <TextField
                    label={`${t('general.password')} *`}
                    name='password'
                    type='password'
                    autoFocus
                    defaultValue=''
                    autoComplete='false'
                    error={!!errors.password}
                    helperText={errors.password && errors.password.message}
                    inputRef={register({
                      validate: requiredTextField,
                      pattern: {
                        value: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[A-Za-z0-9!@$%^&*()_?]{8,30}$/,
                        message: t('validation.invalid-password', { min: '8', max: '30' }),
                      },
                    })}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='end'>
                          <InfoOutlinedIcon
                            className={classes.infoIcon}
                            onClick={() =>
                              infoDialogOpen(
                                t('validation.password.info-dialog'),
                                t('general.password')
                              )
                            }
                          />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label={`${t('general.confirm-password')} *`}
                    name='confirmPassword'
                    type='password'
                    defaultValue=''
                    autoComplete='false'
                    error={!!errors.confirmPassword}
                    helperText={errors.confirmPassword && errors.confirmPassword.message}
                    inputRef={register({
                      validate: (value) =>
                        value === pass || t('validation.invalid-confirmation-password'),
                    })}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Box margin='normal'>
                    {t('general-terms-step.sms-verification-code-was-send', {
                      verificationCodeValidityInMinutes,
                    })}
                    <p>{t('general-terms-step.sms-verification-label')}</p>
                  </Box>
                  <Grid container>
                    <Grid className={classes.gridRelative}>
                      <TextField
                        fullWidth={false}
                        margin='normal'
                        label={`${t('general-terms-step.sms-verification-code')} *`}
                        name='smsVerificationCode'
                        value={smsVerificationCodeValue}
                        onChange={handleUserInput}
                        disabled={generateSmsVerificationCodeLoading}
                        error={
                          !!wrongSmsVerificationCode ||
                          !!expiredSmsVerificationCode ||
                          !!maxSmsVerificationCodeAttemptsReached ||
                          !!errors.smsVerificationCode
                        }
                        helperText={
                          (errors.smsVerificationCode && errors.smsVerificationCode.message) ||
                          (expiredSmsVerificationCode && (
                            <Trans>{t('validation.expired-sms-verification-code')}</Trans>
                          )) ||
                          (maxSmsVerificationCodeAttemptsReached && (
                            <Trans>
                              {t('validation.max-sms-verification-code-attempts-reached')}
                            </Trans>
                          )) ||
                          (wrongSmsVerificationCode && (
                            <Trans>{t('validation.wrong-sms-verification-code')}</Trans>
                          ))
                        }
                        inputRef={register({
                          validate: requiredTextField,
                        })}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position='end'>
                              <InfoOutlinedIcon
                                className={classes.infoIcon}
                                onClick={() =>
                                  infoDialogOpen(
                                    t('validation.sms-code.info-dialog'),
                                    t('general-terms-step.sms-verification-code')
                                  )
                                }
                              />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <div className={classes.buttonOuterWrapper}>
                      <div className={classes.buttonInnerWrapper}>
                        <Button
                          className={classes.generateSmsVerificationCodButton}
                          disabled={generateSmsVerificationCodeLoading}
                          onClick={generateNewCode}
                        >
                          {t('general-terms-step.generate-code-button')}
                        </Button>
                        {generateSmsVerificationCodeLoading && (
                          <CircularProgress size={24} className={classes.buttonProgress} />
                        )}
                      </div>
                      {isSuccessfullyRegenerated && (
                        <Box className={classes.containerSuccess} fontWeight='fontWeightBold'>
                          <DoneIcon className='doneIcon' />
                        </Box>
                      )}
                    </div>
                  </Grid>
                  {generatedVerificationCode && (
                    <p className='checkboxErrorMessage'>{generatedVerificationCode}</p>
                  )}
                </Grid>
                <Grid item xs={12} className={classes.actionsContainer}>
                  <Button
                    type='submit'
                    variant='contained'
                    color='primary'
                    className={classes.button}
                  >
                    {t('general.save')}
                  </Button>
                </Grid>
              </Grid>
            </form>
          </div>
        </StepContent>
      </Step>
      <InfoDialog open={showInfoDialog} title={infoDialogTitle} handleClose={infoDialogClose}>
        {infoDialogContent}
      </InfoDialog>
    </Stepper>
  );
}

export default ManagePassword;
