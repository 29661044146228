const ApplicationActionTypes = {
  RESET_APPLICATION_STATE: 'RESET_APPLICATION_STATE',
  GO_TO_PREVIOUS_STEP: 'GO_TO_PREVIOUS_STEP',
  SET_ACCEPTED_PERSONAL_DATA_CONSENT: 'SET_ACCEPTED_PERSONAL_DATA_CONSENT',
  RESET_VALUE: 'RESET_VALUE',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  RESET_PASSWORD_START: 'RESET_PASSWORD_START',
  SAVE_CLIENT_DATA_START: 'SAVE_CLIENT_DATA_START',
  SAVE_CLIENT_DATA_SUCCESS: 'SAVE_CLIENT_DATA_SUCCESS',
  SAVE_CLIENT_CREDENTIALS_START: 'SAVE_CLIENT_CREDENTIALS_START',
  SAVE_CLIENT_CREDENTIALS_SUCCESS: 'SAVE_CLIENT_CREDENTIALS_SUCCESS',
  RELOAD_REQUIRED_FAILURE: 'RELOAD_REQUIRED_FAILURE',
  GENERAL_SERVER_FAILURE: 'GENERAL_SERVER_FAILURE',
  GENERATE_VERIFICATION_CODE_START: 'GENERATE_VERIFICATION_CODE_START',
  GENERATE_VERIFICATION_CODE_SUCCESS: 'GENERATE_VERIFICATION_CODE_SUCCESS',
  SET_IS_APPLICANT_NAME_OTHER_SELECTED: 'SET_IS_APPLICANT_NAME_OTHER_SELECTED',
  CHANGE_CONDITION: 'CHANGE_CONDITION',
  SAVE_CONTRACT_DATA_START: 'SAVE_CONTRACT_DATA_START',
  SAVE_CONTRACT_DATA_SUCCESS: 'SAVE_CONTRACT_DATA_SUCCESS',
  SAVE_ADDITIONAL_SERVICES_START: 'SAVE_ADDITIONAL_SERVICES_START',
  SAVE_ADDITIONAL_SERVICES_SUCCESS: 'SAVE_ADDITIONAL_SERVICES_SUCCESS',
  START_SESSION_START: 'LOAD_NOMENCLATURES_START',
  START_SESSION_SUCCESS: 'LOAD_NOMENCLATURES_SUCCESS',
  LOAD_APPLICANT_DATA_START: 'LOAD_APPLICANT_DATA_START',
  LOAD_APPLICANT_DATA_SUCCESS: 'LOAD_APPLICANT_DATA_SUCCESS',
  LOAD_APPLICANT_DATA_FAILURE: 'LOAD_APPLICANT_DATA_FAILURE',
  SAVE_APPLICANT_DATA_START: 'SAVE_APPLICANT_DATA_START',
  SAVE_APPLICANT_DATA_SUCCESS: 'SAVE_APPLICANT_DATA_SUCCESS',
  SET_ERROR_OCCURED: 'SET_ERROR_OCCURED',
  SAVE_MERCHANT_DATA_START: 'SAVE_MERCHANT_DATA_START',
  SAVE_MERCHANT_DATA_SUCCESS: 'SAVE_MERCHANT_DATA_SUCCESS',
  SAVE_ACTIVITY_DATA_START: 'SAVE_ACTIVITY_DATA_START',
  SAVE_ACTIVITY_DATA_SUCCESS: 'SAVE_ACTIVITY_DATA_SUCCESS',
  SAVE_CONTACT_PERSON_DATA_START: 'SAVE_CONTACT_PERSON_DATA_START',
  SAVE_CONTACT_PERSON_DATA_SUCCESS: 'SAVE_CONTACT_PERSON_DATA_SUCCESS',
  SAVE_IBAN_DATA_START: 'SAVE_IBAN_DATA_START',
  SAVE_IBAN_DATA_SUCCESS: 'SAVE_IBAN_DATA_SUCCESS',
  CLEAR_APPLICANT_FORM: 'CLEAR_APPLICANT_FORM',
};

export default ApplicationActionTypes;
