import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { theme } from './theme/theme';
import Layout from './components/layout/layout.component';
import Request from './pages/request/request.component';
import ErrorDialog from './components/error-dialog/error-dialog.component';
import ErrorBoundary from './components/error-boundary/error-boundary.component';
import { ApplicationStateProvider } from './providers/application/application.provider';
import StartSession from './pages/start-session/start-session.component';
import './App.css';
import Login from './pages/login/login.component';
import ResetPassword from './pages/reset-password/reset-password.component';

function App() {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <ApplicationStateProvider>
          <ErrorDialog />
          <ErrorBoundary>
            <Switch>
              <Route exact path='/'>
                <StartSession />
              </Route>
              <Route exact path='/Request'>
                <Layout>
                  <Request />
                </Layout>
              </Route>
              <Route exact path='/Login'>
                <Layout isLogin>
                  <Login />
                </Layout>
              </Route>
              <Route exact path='/ResetPassword'>
                <Layout isLogin>
                  <ResetPassword />
                </Layout>
              </Route>
            </Switch>
          </ErrorBoundary>
        </ApplicationStateProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default App;
