import React, { useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Button from '@mui/material/Button';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import {
  useApplicationState,
  useApplicationDispatch,
} from '../../providers/application/application.provider';
import useStyles from './login.styles';
import { isValidEGN, isValidEIK, requiredTextField } from '../../utils/validation.utils';
import StepIcon from '../../components/request/step-icon/step-icon.component';
import RequestInfo from '../../components/request/request-info/request-info.component';
import ManagePassword from '../../components/manage-password/manage-password.component';
import { loginAsync, resetValue } from '../../providers/application/application.actions';

function Login() {
  const classes = useStyles();
  const { register, errors, handleSubmit } = useForm();
  const { t } = useTranslation();
  const history = useHistory();
  const { isPassConfirmed, requestCannotContinue, loginErrorMessage } = useApplicationState();
  const dispatch = useApplicationDispatch();

  useEffect(() => {
    dispatch(resetValue({ pendingPasswordReset: false }));
  }, []);

  const onSubmit = (data) => {
    loginAsync(data, dispatch, () => history.push('/'));
  };

  if (requestCannotContinue) {
    return (
      <div className={classes.root}>
        <RequestInfo />
      </div>
    );
  }

  if (!isPassConfirmed) {
    return (
      <div className={classes.root}>
        <ManagePassword />
      </div>
    );
  }

  return (
    <div className={classes.root}>
      <Link to='/' className={classes.requestLink}>
        {t('login.request-link')}
      </Link>
      <Stepper activeStep={0} orientation='vertical' className={classes.stepper}>
        <Step key={0}>
          <StepLabel StepIconComponent={StepIcon}>{t('general.login')} </StepLabel>
          <StepContent className={classes.stepContent}>
            <div className={classes.stepContentInner}>
              <form onSubmit={handleSubmit(onSubmit)} className={classes.root}>
                <Grid container spacing={3}>
                  <p className='loginErrorMessage'>
                    {loginErrorMessage ||
                      (errors &&
                        ((errors.clientIdentifier && errors.clientIdentifier.message) ||
                          (errors.password && errors.password.message)))}
                  </p>
                  <Grid item xs={12}>
                    <TextField
                      autoFocus
                      label={`${t('general.client-businessIdentifier')} *`}
                      name='clientIdentifier'
                      defaultValue=''
                      inputRef={register({
                        validate: {
                          requiredTextField,
                          validIdentifier: (value) =>
                            isValidEGN(value) || isValidEIK(value) || t('validation.invalid-login'),
                        },
                      })}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label={`${t('general.password')} *`}
                      name='password'
                      type='password'
                      defaultValue=''
                      autoComplete='false'
                      inputRef={register({
                        validate: requiredTextField,
                        pattern: {
                          value: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[A-Za-z0-9!@$%^&*()_?]{8,30}$/,
                          message: t('validation.invalid-login'),
                        },
                      })}
                    />
                  </Grid>
                  <Grid item xs={12} className={classes.actionsContainer}>
                    <Button
                      type='submit'
                      variant='contained'
                      color='primary'
                      className={classes.button}
                    >
                      {t('general.login')}
                    </Button>
                    <Link to='/ResetPassword' className={classes.link}>
                      {t('login.forgotten-pass')}
                    </Link>
                  </Grid>
                </Grid>
              </form>
            </div>
          </StepContent>
        </Step>
      </Stepper>
    </div>
  );
}

export default Login;
