import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  subsection: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(3),
  },
  subsectionLabel: {
    marginLeft: '5px',
    marginBottom: theme.spacing(3),
    fontWeight: 'bolder',
  },
  subsectionInfoIcon: {
    paddingLeft: '3px',
    top: '3px',
    position: 'relative',
    color: '#52ae30',
    cursor: 'pointer',
  },
  emptyGrid: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  removeBtn: {
    padding: '0px !important',
    fontSize: '12px',
    color: '#f44336',
  },
  addBtn: {
    paddingBottom: '0px !important',
  },
}));

export default useStyles;
