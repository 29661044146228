import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
  palette: {
    primary: {
      main: '#52ae30',
    },
    secondary: {
      main: '#52ae30',
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 768,
      md: 996,
      lg: 1336,
      xl: 1920,
    },
  },
  typography: {
    fontFamily: `"Source Sans Pro", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
      "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
      "Segoe UI Symbol", "Noto Color Emoji"`,
    h1: {
      fontFamily: `"Squad",ui-sans-serif,system-ui,-system-ui,-apple-system,BlinkMacSystemFont,
        "Segoe UI",Roboto,Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"`,
      fontWeight: 700,
      fontSize: '40px',
      lineHeight: '48px',
    },
    h2: {
      fontWeight: 700,
      fontSize: '24px',
      lineHeight: '32px',
    },
  },
  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: 'outlined',
        autoComplete: 'off',
        fullWidth: true,
        InputLabelProps: {
          shrink: true,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontWeight: 700,
          fontSize: '16px',
          borderRadius: 30,
          textTransform: 'none',
          padding: '.75rem 2.5rem',
        },
        containedPrimary: {
          color: 'white',
          boxShadow: 'none',
          '&:hover': {
            backgroundColor: '#74bf1e',
            boxShadow: 'none',
          },
        },
        textPrimary: {
          '&:hover': {
            backgroundColor: 'transparent !important',
          },
        },
        label: {
          fontSize: 16,
        },
      },
      defaultProps: {
        disableRipple: true,
        color: 'primary',
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        input: {
          '&:first-of-type': {
            padding: '9.5px 14px !important',
          },
        },
        clearIndicator: {
          display: 'none',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: 30,
          paddingLeft: '18px !important',
          paddingRight: '18px !important',
          [`& legend`]: {
            marginLeft: '18px',
          },
        },
      },
    },
    PrivateNotchedOutline: {
      styleOverrides: {
        root: {
          paddingLeft: 25,
          paddingRight: 25,
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        formControl: {
          left: '18px',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        elevation1: {
          boxShadow: '0px 4px 40px #95959529',
        },
        elevation2: {
          boxShadow: '0 1rem 3rem rgba(73,92,136,0.15)',
        },
      },
    },
    MuiStepIcon: {
      styleOverrides: {
        text: {
          fill: '#ffffff',
        },
      },
    },
    MuiStepConnector: {
      styleOverrides: {
        lineVertical: {
          minHeight: 0,
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          '&:focus': {
            backgroundColor: 'unset',
          },
        },
      },
      defaultProps: {
        variant: 'outlined',
      },
    },
    MuiIconButton: {
      styleOverrides: {
        colorPrimary: {
          '&:hover': {
            backgroundColor: 'transparent !important',
          },
        },
        colorSecondary: {
          '&:hover': {
            backgroundColor: 'transparent !important',
          },
        },
      },
    },
    MuiStepLabel: {
      styleOverrides: {
        label: {
          fontSize: '16px',
          fontWeight: 700,
          '&.Mui-active': {
            fontWeight: 700,
          },
          '&.Mui-completed': {
            fontWeight: 700,
          },
        },
        iconContainer: {
          paddingRight: '10px',
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: '16px 12px 16px 12px',
        },
      },
    },
    MuiInputAdornment: {
      styleOverrides: {
        positionStart: {
          marginRight: '4px',
          paddingLeft: '14px',
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          alignItems: 'start',
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          paddingTop: '0px !important',
          paddingBottom: '0px !important',
        },
      },
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          paddingTop: '0px !important',
          paddingBottom: '0px !important',
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          '@media (min-width: 280px)': {
            borderRadius: 32,
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          border: '1px solid rgba(0, 0, 0, 0.23)',
          backgroundColor: '#ffffff',
          color: '#000000',
        },
        deleteIcon: {
          color: '#52ae30',
          '&:hover': {
            color: '#74bf1e',
          },
        },
      },
    },
  },
});

export default theme;
