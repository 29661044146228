import React from 'react';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import {
  useApplicationState,
  useApplicationDispatch,
} from '../../../providers/application/application.provider';
import {
  saveClientDataAsync,
  setAcceptedPersonalDataConsent,
} from '../../../providers/application/application.actions';
import PhoneNumber from '../phone-number/phone-number.component';
import useStyles from './client-data-step.styles';
import {
  isValidEGN,
  isValidEIK,
  requiredTextField,
  removeCharacterFromNumber,
} from '../../../utils/validation.utils';

const ClientDataStep = ({ formRef, currentStepId }) => {
  const { register, errors, handleSubmit, control, getValues } = useForm();
  const { t } = useTranslation();
  const classes = useStyles();

  const {
    requestNumber,
    clientIdentifier,
    emailAddress,
    emailConfirmed,
    mobilePhoneCountry,
    mobilePhoneNumber,
    mobilePhoneConfirmed,
    acceptedPersonalDataConsent,
    isReadOnly,
    terminalType,
    nomenclatures: { mobilePhoneCodes, countryBulgaria, gdprConsentUrl },
  } = useApplicationState();
  const dispatch = useApplicationDispatch();

  const [isBulgariaCountryCodeSelected, setIsBulgariaCountryCodeSelected] = React.useState(
    mobilePhoneCountry === countryBulgaria
  );
  const handleCountryCodeChange = (code) => {
    setIsBulgariaCountryCodeSelected(code === countryBulgaria);
  };

  const handlePersonalDataConsentChange = () => {
    dispatch(setAcceptedPersonalDataConsent(!acceptedPersonalDataConsent));
  };

  const onSubmit = () => {
    const data = getValues();
    const phoneNumber = isBulgariaCountryCodeSelected
      ? data.mobilePhoneNumber.replace(/^0+/, '')
      : data.mobilePhoneNumber;

    saveClientDataAsync(
      {
        ...data,
        clientIdentifier: data.clientIdentifier.trim(),
        mobilePhoneNumber: phoneNumber,
        requestNumber,
        currentStepId,
        terminalType,
      },
      dispatch
    );
  };

  return (
    <form ref={formRef} onSubmit={handleSubmit(onSubmit)} noValidate>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <TextField
            label={`${t('general.client-businessIdentifier')} *`}
            name='clientIdentifier'
            defaultValue={clientIdentifier}
            disabled={emailConfirmed || isReadOnly}
            autoFocus
            inputProps={{
              maxLength: 13,
            }}
            error={!!errors.clientIdentifier}
            helperText={errors.clientIdentifier && errors.clientIdentifier.message}
            inputRef={register({
              validate: {
                requiredTextField,
                validIdentifier: (value) =>
                  isValidEGN(value) || isValidEIK(value) || t('validation.text-field.invalid'),
              },
            })}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label={`${t('client-data-step.email-address')} *`}
            name='emailAddress'
            type='email'
            defaultValue={emailAddress}
            disabled={emailConfirmed || isReadOnly}
            inputProps={{
              maxLength: 40,
            }}
            error={!!errors.emailAddress}
            helperText={errors.emailAddress && errors.emailAddress.message}
            inputRef={register({
              validate: requiredTextField,
              pattern: {
                value: /^[a-zA-Z0-9._%+-]+@(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,63}$/,
                message: t('validation.text-field.invalid'),
              },
            })}
          />
        </Grid>
        <Grid item xs={12} sm={6} className={classes.gridRelative}>
          <PhoneNumber
            options={mobilePhoneCodes}
            selectedOption={mobilePhoneCountry}
            selectedOptionOnChange={(code) => handleCountryCodeChange(code)}
            selectorName='mobilePhoneCountry'
            selectorValidation={{
              validate: (value) => !!value || t('validation.mobile-phone-code.required'),
            }}
            defaultValue={mobilePhoneNumber}
            propertyName='mobilePhoneNumber'
            inputProps={{
              maxLength: isBulgariaCountryCodeSelected ? 10 : 14,
            }}
            inputRef={register({
              validate: {
                requiredTextField,
                validPhoneNumber: (value) =>
                  (isBulgariaCountryCodeSelected
                    ? /^[0-9]{9}$/.test(removeCharacterFromNumber(value).replace(/^0+/, ''))
                    : /^[0-9]{1,14}$/.test(removeCharacterFromNumber(value))) ||
                  t('validation.text-field.invalid'),
              },
            })}
            mobilePhoneCountryError={errors.mobilePhoneCountry}
            mobilePhoneNumberError={errors.mobilePhoneNumber}
            control={control}
            disabled={mobilePhoneConfirmed || isReadOnly}
            infoDialogTitle={t('client-data-step.mobile-phone-number')}
            infoDialogContent={t('client-data-step.mobile-phone-number.info-dialog')}
            showInfoDialogIcon
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                name='acceptedPersonalDataConsent'
                checked={acceptedPersonalDataConsent}
                onClick={handlePersonalDataConsentChange}
                disabled={isReadOnly}
                inputRef={register({
                  required: {
                    value: true,
                    message: t('validation.text-field.required'),
                  },
                })}
              />
            }
            label={
              <span>
                {t('client-data-step.accepted-personal-data-consent')}{' '}
                <a href={gdprConsentUrl} target='_blank' rel='noopener noreferrer'>
                  {t('client-data-step.accepted-personal-data-consent-link')}&nbsp;*
                </a>
              </span>
            }
          />
          {errors.acceptedPersonalDataConsent && !acceptedPersonalDataConsent && (
            <p className='checkboxErrorMessage'>{errors.acceptedPersonalDataConsent.message}</p>
          )}
        </Grid>
      </Grid>
    </form>
  );
};

export default ClientDataStep;
