import React from 'react';
import { useHistory, Redirect, useLocation } from 'react-router-dom';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import WbIncandescentTwoToneIcon from '@mui/icons-material/WbIncandescentTwoTone';
import { Trans, useTranslation } from 'react-i18next';
import useStyles from './request.styles';
import ClientDataStep from '../../components/request/client-data-step/client-data-step.component';
import ApplicantDataStep from '../../components/request/applicant-data-step/applicant-data-step.component';
import AdditionalDataStep from '../../components/request/additional-data-step/additional-data-step.component';
import RequestSuccess from '../../components/request/request-success/request-success.component';
import {
  useApplicationState,
  useApplicationDispatch,
} from '../../providers/application/application.provider';
import { goToPreviousStep } from '../../providers/application/application.actions';
import RequestInfo from '../../components/request/request-info/request-info.component';
import StepIcon from '../../components/request/step-icon/step-icon.component';
import ReloadRequiredPrompt from '../../components/reload-required-prompt/reload-required-prompt.component';
import ContactPersonStep from '../../components/request/contact-person-step/contact-person-step.component';
import ActivityDataStep from '../../components/request/activity-data-step/activity-data-step.component';
import IbanStep from '../../components/request/iban-step/iban-step.component';
import MerchantDataStep from '../../components/request/merchant-data-step/merchant-data-step.component';

function getStepContent(stepId, step, formRefs, backRefs) {
  switch (stepId) {
    case 1:
      return <ClientDataStep formRef={formRefs[step]} currentStepId={stepId} />;
    case 2:
      return (
        <MerchantDataStep
          formRef={formRefs[step]}
          backRef={backRefs[step]}
          currentStepId={stepId}
        />
      );
    case 3:
      return (
        <ApplicantDataStep
          formRef={formRefs[step]}
          backRef={backRefs[step]}
          currentStepId={stepId}
        />
      );
    case 4:
      return (
        <ContactPersonStep
          formRef={formRefs[step]}
          backRef={backRefs[step]}
          currentStepId={stepId}
        />
      );
    case 5:
      return (
        <ActivityDataStep
          formRef={formRefs[step]}
          backRef={backRefs[step]}
          currentStepId={stepId}
        />
      );
    case 6:
      return <AdditionalDataStep formRef={formRefs[step]} currentStepId={stepId} />;
    case 7:
      return <IbanStep formRef={formRefs[step]} backRef={backRefs[step]} currentStepId={stepId} />;
    default:
      return '';
  }
}

function Request() {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();

  const {
    isInitialDataLoaded,
    loading,
    requestCannotContinue,
    confirmationEmailPending,
    isPassConfirmed,
    isSessionStarted,
    emailConfirmed,
    personalIdentifierEntered,
    activeStepIndex,
    existsActiveRequest,
    nomenclatures: { steps, smeOnboardingUrl },
  } = useApplicationState();
  const dispatch = useApplicationDispatch();

  const formRefs = steps.map(() => React.createRef());
  const backRefs = steps.map(() => React.createRef());

  const location = useLocation();

  const handleNext = () => {
    const formRef = formRefs[activeStepIndex];
    if (formRef && formRef.current) {
      formRef.current.requestSubmit();
    }
  };

  const handleBack = () => {
    const backRef = backRefs[activeStepIndex];
    if (backRef && backRef.current) {
      dispatch(goToPreviousStep(backRef.current.getValues()));
    } else {
      dispatch(goToPreviousStep());
    }
  };

  if (!isInitialDataLoaded) {
    return <Redirect push to={`/${location.search}`} />;
  }

  if (!isSessionStarted || !isPassConfirmed) {
    return <Redirect push to='/Login' />;
  }

  if (activeStepIndex >= steps.length) {
    return (
      <div className={classes.root}>
        <RequestSuccess />
      </div>
    );
  }

  if (
    requestCannotContinue ||
    confirmationEmailPending ||
    personalIdentifierEntered ||
    existsActiveRequest
  ) {
    return (
      <div className={classes.root}>
        <RequestInfo />
      </div>
    );
  }

  return (
    <div className={classes.root}>
      {!emailConfirmed && (
        <Grid container spacing={3} className={classes.initialInfo}>
          <Grid item xs={12} className={classes.initialInfoBox}>
            <Trans>{t('initial-info.main-text')}</Trans>
          </Grid>
          <Grid item xs={12} sm={6} className={classes.loginBox}>
            <h3 className={classes.loginBoxText}>{t('initial-info.login-line')}</h3>
            <Button
              variant='contained'
              color='primary'
              className={classes.loginButton}
              onClick={() => history.push('/Login')}
            >
              {t('client-data-step.login-link')}
            </Button>
          </Grid>
          <Grid
            container
            item
            xs={12}
            sm={6}
            columnSpacing={1}
            className={classes.mandatoryConditionsBox}
          >
            <Grid item sm={1} className={classes.mandatoryConditionsIconGrid}>
              <WbIncandescentTwoToneIcon className={classes.mandatoryConditionsIcon} />
            </Grid>
            <Grid item sm={11} className={classes.mandatoryConditionsBoxText}>
              <Trans i18nKey='initial-info.text-with-link'>
                <Box
                  component='a'
                  href={smeOnboardingUrl}
                  target='_blank'
                  rel='noopener noreferrer'
                />
              </Trans>
            </Grid>
          </Grid>
        </Grid>
      )}
      <Stepper activeStep={activeStepIndex} orientation='vertical' className={classes.stepper}>
        {steps.map((step, index) => (
          <Step key={step.name}>
            <StepLabel StepIconComponent={StepIcon}>{step.name} </StepLabel>
            <StepContent className={classes.stepContent}>
              <div className={classes.stepContentInner}>
                {getStepContent(step.id, index, formRefs, backRefs)}
              </div>
              <div className={classes.actionsContainer}>
                <div>
                  <Box component='span' display={{ xs: 'none', md: 'inline' }}>
                    {activeStepIndex === 0 ? (
                      ''
                    ) : (
                      <Button
                        disabled={activeStepIndex === 0}
                        onClick={handleBack}
                        className={classes.button}
                      >
                        {t('general.backward')}
                      </Button>
                    )}
                  </Box>
                  <span className={classes.buttonForwardOuterWrapper}>
                    <span className={classes.buttonForwardInnerWrapper}>
                      <Button
                        id={`btnForward_${steps[activeStepIndex].nameEN}`}
                        variant='contained'
                        color='primary'
                        onClick={handleNext}
                        className={classes.button}
                        disabled={loading}
                      >
                        {activeStepIndex === steps.length - 1
                          ? t('general.complete')
                          : t('general.forward')}
                      </Button>
                      {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                    </span>
                  </span>
                  <Box component='span' display={{ xs: 'inline', md: 'none' }}>
                    {activeStepIndex === 0 ? (
                      ''
                    ) : (
                      <Button
                        disabled={activeStepIndex === 0}
                        onClick={handleBack}
                        className={classes.button}
                      >
                        {t('general.backward')}
                      </Button>
                    )}
                  </Box>
                </div>
              </div>
            </StepContent>
          </Step>
        ))}
      </Stepper>
      <ReloadRequiredPrompt />
    </div>
  );
}

export default Request;
