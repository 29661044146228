import React from 'react';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Header from '../header/header.component';
import Footer from '../footer/footer.component';
import useStyles from './layout.styles';

const Layout = (props) => {
  const { children, isLogin } = props;
  const classes = useStyles();

  return (
    <div>
      <Container maxWidth='lg' className={classes.mainContainer}>
        <Header />
        <Container className={isLogin ? classes.loginContentContainer : classes.contentContainer}>
          <Paper className={isLogin ? classes.loginPaper : classes.paper} elevation={1}>
            {children}
          </Paper>
        </Container>
        <Footer />
      </Container>
    </div>
  );
};

export default Layout;
