import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import {
  useApplicationState,
  useApplicationDispatch,
} from '../../providers/application/application.provider';
import useStyles from './reset-password.styles';
import { isValidEGN, isValidEIK, requiredTextField } from '../../utils/validation.utils';
import StepIcon from '../../components/request/step-icon/step-icon.component';
import RequestInfo from '../../components/request/request-info/request-info.component';
import { resetPasswordAsync, resetValue } from '../../providers/application/application.actions';

function ResetPassword() {
  const classes = useStyles();
  const { register, errors, handleSubmit } = useForm();
  const { t } = useTranslation();
  const { requestCannotContinue, pendingPasswordReset } = useApplicationState();
  const dispatch = useApplicationDispatch();

  useEffect(() => {
    dispatch(resetValue({ loginErrorMessage: '' }));
  }, []);

  const onSubmit = (data) => {
    resetPasswordAsync(data, dispatch);
  };

  if (requestCannotContinue || pendingPasswordReset) {
    return (
      <div className={classes.root}>
        <RequestInfo />
      </div>
    );
  }

  return (
    <div className={classes.root}>
      <Link to='/Login' className={classes.loginLink}>
        {t('client-data-step.login-link')}
      </Link>
      <Stepper activeStep={0} orientation='vertical' className={classes.stepper}>
        <Step key={0}>
          <StepLabel StepIconComponent={StepIcon}>{t('login.forgotten-pass')} </StepLabel>
          <StepContent className={classes.stepContent}>
            <div className={classes.stepContentInner}>
              <form onSubmit={handleSubmit(onSubmit)} className={classes.root}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <TextField
                      label={`${t('general.client-businessIdentifier')} *`}
                      name='clientIdentifier'
                      defaultValue=''
                      autoFocus
                      inputProps={{
                        maxLength: 13,
                      }}
                      error={!!errors.clientIdentifier}
                      helperText={errors.clientIdentifier && errors.clientIdentifier.message}
                      inputRef={register({
                        validate: {
                          requiredTextField,
                          validIdentifier: (value) =>
                            isValidEGN(value) ||
                            isValidEIK(value) ||
                            t('validation.text-field.invalid'),
                        },
                      })}
                    />
                  </Grid>
                  <Grid item xs={12} className={classes.actionsContainer}>
                    <Button
                      type='submit'
                      variant='contained'
                      color='primary'
                      className={classes.button}
                    >
                      {t('continue')}
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </div>
          </StepContent>
        </Step>
      </Stepper>
    </div>
  );
}

export default ResetPassword;
