import React, { useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import useStyles from './request-success.styles';
import SuccessIcon from '../../../assets/images/success_icon.svg';
import { signOut } from '../../../providers/application/application.actions';
import { useApplicationState } from '../../../providers/application/application.provider';

const RequestSuccess = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const {
    isRequestCompleted,
    terminalType,
    terminalTypeVirtualPosId,
    nomenclatures: { smeOnboardingUrl },
  } = useApplicationState();

  useEffect(() => {
    signOut();
  }, []);

  return (
    <Grid container spacing={2} justifyContent='center'>
      <Grid className={classes.gridItem} item xs={12}>
        <img src={SuccessIcon} alt='' />
      </Grid>
      <Grid className={classes.gridItem} item xs={12}>
        {!isRequestCompleted ? (
          <Typography component='h2' variant='h2'>
            {t('request-success.title.registration-data-pending', {
              posType: terminalType === terminalTypeVirtualPosId ? t('virtual') : '',
            })}
          </Typography>
        ) : (
          <Typography component='h2' variant='h2'>
            {t('request-success.title.registration-data-completed')}
          </Typography>
        )}
      </Grid>
      <Grid className={classes.gridItem} item xs={12}>
        {!isRequestCompleted ? (
          <Typography component='h6' variant='h6'>
            {t('request-success.subtitle.registration-data-pending.line1')}
            <br />
            {t('request-success.subtitle.registration-data-pending.line2')}
            <Box component='a' href={smeOnboardingUrl} target='_blank' rel='noopener noreferrer'>
              <b>{t('request-success.subtitle.registration-data-pending.link')}</b>
            </Box>
            .
          </Typography>
        ) : (
          <Typography component='h6' variant='h6'>
            {t('request-success.subtitle.registration-data-completed')}
          </Typography>
        )}
      </Grid>
    </Grid>
  );
};

export default RequestSuccess;
