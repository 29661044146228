import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
  root: {
    '& .MuiInputBase-input': {
      width: '50% !important',
    },
  },
  innerSelect: {
    '& .MuiInputBase-root': {
      paddingLeft: '0px !important',
      marginLeft: '-14px !important',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },

    width: '100%',
  },
  inputAdornment: {
    width: '50%',
  },
  infoIcon: {
    color: '#52ae30',
    cursor: 'pointer',
  },
}));

export default useStyles;
