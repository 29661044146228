import React from 'react';
import { Snackbar, Button } from '@mui/material';
import { useApplicationState } from '../../providers/application/application.provider';

const ReloadRequiredPrompt = () => {
  const { reloadRequired, reloadRequiredMessage, reloadRequiredButtonText } = useApplicationState();

  const handleReloadClick = () => {
    window.location.reload(true);
  };

  return (
    <Snackbar
      open={reloadRequired}
      message={reloadRequiredMessage}
      action={
        <Button color='secondary' size='small' onClick={handleReloadClick}>
          {reloadRequiredButtonText}
        </Button>
      }
    />
  );
};

export default ReloadRequiredPrompt;
