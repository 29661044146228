import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  radioButton: {
    paddingRight: '10px',
  },
  merchantSectionInfo: {
    position: 'relative',
    height: '56px',
    width: '935px',
    left: '0',
    right: '0',
    marginLeft: '15px',
    borderRadius: '24px',
    backgroundColor: '#ECEFF6',
  },
  infoText: {
    position: 'absolute',
    height: '56px',
    marginTop: '15px',
    marginLeft: '55px',
    '@media (max-width:460px)': {
      marginTop: '8px',
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: '20px',
      marginRight: '20px',
    },
    '@media (max-width:286px)': {
      marginRight: '0px',
    },
  },
  bulbIcon: {
    marginLeft: '25px',
    marginRight: '10px',
    marginTop: '11px',
    transform: 'rotate(180deg)',
    color: '#52AE30',
  },
  iconGrid: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  infoIcon: {
    color: '#52ae30',
    cursor: 'pointer',
  },
  icon: {
    marginRight: '40px',
  },
}));

export default useStyles;
