import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationBG from './translation-bg.json';

i18n.use(initReactI18next).init({
  resources: {
    bg: {
      translations: translationBG,
    },
  },
  lng: 'bg',
  fallbackLng: 'bg',
  ns: ['translations'],
  defaultNS: 'translations',
  keySeparator: false,
});

export default i18n;
