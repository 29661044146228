import React, { useImperativeHandle } from 'react';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import InputAdornment from '@mui/material/InputAdornment';
import {
  useApplicationState,
  useApplicationDispatch,
} from '../../../providers/application/application.provider';
import InfoDialog from '../../info-dialog/info-dialog.component';
import useStyles from './iban-step.styles';
import { saveIbanDataAsync } from '../../../providers/application/application.actions';
import { isDskIban, isValidIban, requiredTextField } from '../../../utils/validation.utils';

const IbanStep = ({ formRef, backRef, currentStepId }) => {
  const { handleSubmit, errors, register, getValues } = useForm();
  const { t } = useTranslation();
  const classes = useStyles();

  const {
    iban,
    isIbanFound,
    terminalType,
    nomenclatures: { terminalTypeVirtualPosId },
  } = useApplicationState();
  const dispatch = useApplicationDispatch();

  const [showInfoDialog, setShowInfoDialog] = React.useState(false);
  const [infoDialogContent, setInfoDialogContent] = React.useState('');
  const [infoDialogTitle, setInfoDialogTitle] = React.useState('');

  const infoDialogOpen = (text, title) => {
    setInfoDialogContent(text);
    setInfoDialogTitle(title);
    setShowInfoDialog(true);
  };

  const infoDialogClose = () => {
    setShowInfoDialog(false);
  };
  const onSubmit = (data) => {
    saveIbanDataAsync({ ...data, currentStepId }, dispatch);
  };

  useImperativeHandle(backRef, () => ({
    getValues() {
      return getValues();
    },
  }));

  return (
    <form ref={formRef} onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} className={classes.gridRelative}>
          <TextField
            label={`${t('iban-step.iban')} *`}
            name='iban'
            defaultValue={iban}
            inputProps={{
              maxLength: 22,
            }}
            error={!!errors.iban || !isIbanFound}
            helperText={
              (errors.iban && errors.iban.message) ||
              (!isIbanFound &&
                (terminalType === terminalTypeVirtualPosId
                  ? t('validation.invalid-iban-virtual-pos')
                  : t('validation.invalid-iban')))
            }
            inputRef={register({
              validate: {
                requiredTextField,
                validIban: (value) =>
                  (isValidIban(value) && isDskIban(value)) ||
                  (terminalType === terminalTypeVirtualPosId
                    ? t('validation.invalid-iban-virtual-pos')
                    : t('validation.invalid-iban')),
              },
            })}
            // eslint-disable-next-line react/jsx-no-duplicate-props
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <InfoOutlinedIcon
                    className={classes.infoIcon}
                    onClick={() =>
                      infoDialogOpen(
                        t(
                          'iban-step.info-dialog',
                          terminalType === terminalTypeVirtualPosId
                            ? { pos: t('virtual-pos') }
                            : { pos: t('terminal') }
                        ),
                        t('iban-step.iban')
                      )
                    }
                  />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      </Grid>
      <InfoDialog open={showInfoDialog} title={infoDialogTitle} handleClose={infoDialogClose}>
        {infoDialogContent}
      </InfoDialog>
    </form>
  );
};

export default IbanStep;
