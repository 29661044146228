import i18n from 'i18next';

export function isLeapYear(yyyy) {
  if (yyyy % 400 === 0) {
    return true;
  }
  if (yyyy % 100 === 0) {
    return false;
  }
  if (yyyy % 4 === 0) {
    return true;
  }

  return false;
}

export function isValidEGN(input) {
  const egn = input ? input.trim() : '';
  if (egn.length !== 10) {
    return false;
  }

  const digits = [];
  const coeffs = [2, 4, 8, 5, 10, 9, 7, 3, 6];
  const days = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

  for (let i = 0; i < egn.length; i += 1) {
    const digit = parseInt(egn.charAt(i), 10);

    if (Number.isNaN(digit)) {
      break;
    }

    digits[i] = digit;
  }

  if (digits.length !== 10) {
    return false;
  }

  const dd = digits[4] * 10 + digits[5];
  let mm = digits[2] * 10 + digits[3];
  const yy = digits[0] * 10 + digits[1];
  let yyyy = null;

  if (mm >= 1 && mm <= 12) {
    yyyy = 1900 + yy;
  } else if (mm >= 21 && mm <= 32) {
    mm -= 20;
    yyyy = 1800 + yy;
  } else if (mm >= 41 && mm <= 52) {
    mm -= 40;
    yyyy = 2000 + yy;
  } else {
    return false;
  }

  days[1] += isLeapYear(yyyy) ? 1 : 0;

  if (!(dd >= 1 && dd <= days[mm - 1])) {
    return false;
  }

  let checksum = 0;

  for (let j = 0; j < coeffs.length; j += 1) {
    checksum += digits[j] * coeffs[j];
  }

  checksum %= 11;

  if (checksum === 10) {
    checksum = 0;
  }

  if (digits[9] !== checksum) {
    return false;
  }

  return true;
}

export function isValidEIK(input) {
  const t = [2, 7, 3, 5, 4, 9, 5, 7];
  let rr;
  let i;
  let chs;
  const s = input ? input.trim() : '';

  switch (s.length) {
    case 9:
      if (s === '000000000') return false;

      rr = 0;
      for (i = 0; i < 8; i += 1) {
        rr += s[i] * (i + 1);
      }
      chs = rr % 11;
      if (chs === 10) {
        rr = 0;
        for (i = 0; i < 8; i += 1) {
          rr += s[i] * (i + 3);
        }
        chs = rr % 11;
      }
      return chs % 10 === parseInt(s[8], 10);
    case 13:
      if (s === '0000000000000') return false;

      if (isValidEIK(s.substr(0, 9)) === true) {
        rr = 0;
        for (i = 8; i < 12; i += 1) {
          rr += s[i] * t[i - 8];
        }
        chs = rr % 11;
        if (chs === 10) {
          rr = 0;
          for (i = 8; i < 12; i += 1) {
            rr += s[i] * t[i - 4];
          }
          chs = rr % 11;
        }
        return chs % 10 === parseInt(s[12], 10);
      }
      return false;
    default:
      return false;
  }
}

export function isDskIban(value) {
  if (value.length !== 22) {
    return false;
  }
  const countryCode = 'BG';
  const swiftCode = 'STSA';

  const countryCodeToCheck = value.substring(0, 2);
  const swiftCodeToCheck = value.substring(4, 8);

  if (countryCodeToCheck !== countryCode) {
    return false;
  }
  if (swiftCodeToCheck !== swiftCode) {
    return false;
  }
  return true;
}

export function isValidIban(value) {
  // remove spaces and to upper case
  const iban = value.replace(/ /g, '').toUpperCase();
  let ibancheckdigits = '';
  let leadingZeroes = true;
  let cRest = '';
  let cOperator = '';
  let charAt;
  let cChar;
  let ibanregexp;
  let i;
  let p;

  // check the country code and find the country specific format
  const countrycode = iban.substring(0, 2);
  const bbancountrypatterns = {
    AD: '\\d{8}[\\da-zA-Z]{12}',
    AE: '\\d{3}\\d{16}',
    AL: '\\d{8}[\\da-zA-Z]{16}',
    AT: '\\d{16}',
    AZ: '[\\da-zA-Z]{4}\\d{20}',
    BA: '\\d{16}',
    BE: '\\d{12}',
    BG: '[a-zA-Z]{4}\\d{6}[\\da-zA-Z]{8}',
    BH: '[a-zA-Z]{4}[\\dA-Z]{14}',
    BR: '\\d{23}[a-zA-Z][\\da-zA-Z]',
    CH: '\\d{5}[\\da-zA-Z]{12}',
    CR: '\\d{17}',
    CY: '\\d{8}[\\da-zA-Z]{16}',
    CZ: '\\d{20}',
    DE: '\\d{18}',
    DK: '\\d{14}',
    DO: '[a-zA-Z]{4}\\d{20}',
    EE: '\\d{16}',
    ES: '\\d{20}',
    FI: '\\d{14}',
    FO: '\\d{14}',
    FR: '\\d{10}[\\da-zA-Z]{11}\\d{2}',
    GB: '[a-zA-Z]{4}\\d{14}',
    GE: '[\\da-zA-Z]{2}\\d{16}',
    GI: '[a-zA-Z]{4}[\\da-zA-Z]{15}',
    GL: '\\d{14}',
    GR: '\\d{7}[\\da-zA-Z]{16}',
    GT: '[\\da-zA-Z]{4}[\\da-zA-Z]{20}',
    HR: '\\d{17}',
    HU: '\\d{24}',
    IE: '[\\da-zA-Z]{4}\\d{14}',
    IL: '\\d{19}',
    IS: '\\d{22}',
    IT: '[a-zA-Z]\\d{10}[\\da-zA-Z]{12}',
    KZ: '\\d{3}[\\da-zA-Z]{13}',
    KW: '[a-zA-Z]{4}[\\da-zA-Z]{22}',
    LB: '\\d{4}[\\da-zA-Z]{20}',
    LI: '\\d{5}[\\da-zA-Z]{12}',
    LT: '\\d{16}',
    LU: '\\d{3}[\\da-zA-Z]{13}',
    LV: '[a-zA-Z]{4}[\\da-zA-Z]{13}',
    MC: '\\d{10}[\\da-zA-Z]{11}\\d{2}',
    ME: '\\d{18}',
    MD: '[\\da-zA-Z]{2}\\d{18}',
    MK: '\\d{3}[\\da-zA-Z]{10}\\d{2}',
    MR: '\\d{23}',
    MT: '[a-zA-Z]{4}\\d{5}[\\da-zA-Z]{18}',
    MU: '[a-zA-Z]{4}\\d{19}[a-zA-Z]{3}',
    NO: '\\d{11}',
    NL: '[a-zA-Z]{4}\\d{10}',
    PK: '[\\da-zA-Z]{4}\\d{16}',
    PL: '\\d{24}',
    PS: '[\\da-zA-Z]{4}\\d{21}',
    PT: '\\d{21}',
    RO: '[a-zA-Z]{4}[\\da-zA-Z]{16}',
    RS: '\\d{18}',
    SA: '\\d{2}[\\da-zA-Z]{18}',
    SE: '\\d{20}',
    SI: '\\d{15}',
    SK: '\\d{20}',
    SM: '[a-zA-Z]\\d{10}[\\da-zA-Z]{12}',
    TN: '\\d{20}',
    TR: '\\d{5}[\\da-zA-Z]{17}',
    VG: '[\\da-zA-Z]{4}\\d{16}',
  };

  const bbanpattern = bbancountrypatterns[countrycode];
  // As new countries will start using IBAN in the
  // future, we only check if the countrycode is known.
  // This prevents false negatives, while almost all
  // false positives introduced by this, will be caught
  // by the checksum validation below anyway.
  // Strict checking should return FALSE for unknown
  // countries.
  if (typeof bbanpattern !== 'undefined') {
    ibanregexp = new RegExp(`^[A-Z]{2}\\d{2}${bbanpattern}$`, '');
    if (!ibanregexp.test(iban)) {
      return false; // invalid country specific format
    }
  }

  // now check the checksum, first convert to digits
  const ibancheck = iban.substring(4, iban.length) + iban.substring(0, 4);
  for (i = 0; i < ibancheck.length; i += 1) {
    charAt = ibancheck.charAt(i);
    if (charAt !== '0') {
      leadingZeroes = false;
    }
    if (!leadingZeroes) {
      ibancheckdigits += '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ'.indexOf(charAt);
    }
  }

  // calculate the result of: ibancheckdigits % 97
  for (p = 0; p < ibancheckdigits.length; p += 1) {
    cChar = ibancheckdigits.charAt(p);
    cOperator = `${cRest}${cChar}`;
    cRest = cOperator % 97;
  }
  return cRest === 1;
}

export function requiredTextField(value) {
  return !!value.trim() || i18n.t('validation.text-field.required');
}

export function removeCharacterFromNumber(value) {
  return value.replace(/\D/g, '');
}
