import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
  personalDataConsentLink: {
    color: '#52ae30',
  },
  gridRelative: {
    position: 'relative',
  },
}));

export default useStyles;
