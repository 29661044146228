import ApplicationActionTypes from './application.types';

export const initialState = {
  isInitialDataLoaded: false,
  requestNumber: '',
  token: '',
  clientIdentifier: '',
  emailAddress: '',
  emailConfirmed: false,
  mobilePhoneCountry: '',
  mobilePhoneNumber: '',
  acceptedPersonalDataConsent: false,
  requestCannotContinue: false,
  existsActiveRequest: false,
  confirmationEmailPending: false,
  generatedVerificationCode: '',
  shouldGenerateVerificationCode: false,
  verificationCodeValidityInMinutes: '',
  applicantIdentifier: '',
  applicantNames: '',
  applicantMobilePhoneCountry: '',
  applicantMobilePhoneNumber: '',
  applicantMobilePhoneConfirmed: false,
  applicantEmail: '',
  applicants: [],
  applicantId: '',
  isApplicantNameOtherSelected: false,
  activeStepIndex: 0,
  loading: false,
  language: 'bg',
  errorOccured: false,
  reloadRequired: false,
  wrongSmsVerificationCode: false,
  expiredSmsVerificationCode: false,
  maxSmsVerificationCodeAttemptsReached: false,
  generateSmsVerificationCodeLoading: false,
  merchantName: '',
  merchantLocation: '',
  merchantAddress: '',
  merchantPostCode: '',
  isMerchantNameFromRegix: false,
  isMerchantAddressFromRegix: false,
  isMerchantLocationFromRegix: false,
  isMerchantPostCodeFromRegix: false,
  applicantType: '',
  terminalType: null,
  terminalTypeOther: '',
  contactPersonNames: '',
  contactPersonTitle: null,
  contactPersonEmail: '',
  contactMobilePhoneCountry: '',
  contactMobilePhoneNumber: '',
  isPassConfirmed: true,
  isSessionStarted: false,
  isContactAddressSameAsRegistrationAddress: '',
  isContactPersonSameAsApplicantPerson: '',
  contactAddressProvince: '',
  contactAddressMunicipality: '',
  contactAddressSettlement: '',
  contactAddressLocation: '',
  contactAddressPostCode: '',
  merchantDataComment: '',
  authorization: null,
  refunding: false,
  payByLink: null,
  oneClickPayment: null,
  recurringPayments: false,
  hasCardlessAuthorization: null,
  hasPosCashRegister: null,
  hasCardPresenceAuthorization: null,
  merchantNameBG: '',
  merchantNameEN: '',
  websiteURL: null,
  websiteIP: '',
  websiteOwnership: null,
  websiteOwnershipOther: '',
  isActivityFromRegix: false,
  activity: '',
  isMainActivityClassificationFromRegix: false,
  mainActivityClassification: '',
  commercialPOSActivity: '',
  isObjectAddressSameAsRegistrationAddress: '',
  products: [
    {
      identifier: 0,
      productIdentifier: null,
      productGroups: '',
      license: null,
      licenseOther: '',
      productType: null,
      ownProductType: null,
      ownProductTypeOther: '',
    },
  ],
  averageOrderPrice: null,
  previousYearTurnover: null,
  averageMonthlyPOSTurnover: null,
  averageMonthlyTransactions: null,
  activityComment: '',
  objectAddressProvince: '',
  objectAddressMunicipality: '',
  objectAddressSettlement: '',
  objectAddressLocation: '',
  objectAddressPostCode: '',
  loginErrorMessage: '',
  pendingPasswordReset: false,
  iban: '',
  isIbanFound: true,
  isReadOnly: false,
  isRequestCompleted: false,
  websitePlatformType: null,
  websitePlatformTypeOther: '',
  paymentPageLanguage: '',
  terminalCurrency: '',
  posTerminalsCount: null,
  isSeasonalObject: null,
  seasonalityId: null,
  seasonalityComment: '',
  posDeviceTypeId: null,
  ObjectOwnership: null,
  nomenclatures: {
    mobilePhoneCodes: [],
    countries: [],
    licenses: [],
    applicantTypes: [],
    ownProductTypes: [],
    processes: [],
    productTypes: [],
    steps: [],
    terminalTypes: [],
    terminalTypeOtherId: '',
    terminalTypeVirtualPosId: '',
    terminalTypeSoftwarePosId: '',
    websiteOwnerships: [],
    licenseOtherId: '',
    productTypeOwnId: '',
    ownProductTypeOtherId: '',
    websiteOwnershipOtherId: '',
    countryBulgaria: '',
    agentApplicantType: '',
    smeOnboardingUrl: '',
    gdprConsentUrl: '',
    maxActivityDataProducts: 0,
    websitePlatformTypes: [],
    websitePlatformTypeOtherId: '',
    paymentPageLanguages: [],
    terminalCurrencies: [],
    seasonalities: [],
    posDeviceTypes: [],
    paymentPageLanguageBulgarianId: '',
    terminalCurrencyBGNId: '',
    temporaryUsageId: '',
    objectOwnershipOtherId: '',
    objectOwnerships: [],
  },
};

function applicationReducer(state, action) {
  switch (action.type) {
    case ApplicationActionTypes.RESET_APPLICATION_STATE: {
      return { ...initialState, ...action.payload };
    }
    case ApplicationActionTypes.GO_TO_PREVIOUS_STEP: {
      return {
        ...state,
        ...action.payload,
        activeStepIndex: state.activeStepIndex - 1,
      };
    }
    case ApplicationActionTypes.SET_ACCEPTED_PERSONAL_DATA_CONSENT: {
      return { ...state, ...action.payload };
    }
    case ApplicationActionTypes.RESET_VALUE: {
      return { ...state, ...action.payload };
    }
    case ApplicationActionTypes.LOGIN_SUCCESS: {
      return { ...state, ...action.payload };
    }
    case ApplicationActionTypes.RESET_PASSWORD_START: {
      return { ...state, pendingPasswordReset: true };
    }
    case ApplicationActionTypes.SAVE_CLIENT_DATA_START: {
      return { ...state, loading: true };
    }
    case ApplicationActionTypes.SAVE_CLIENT_DATA_SUCCESS: {
      return {
        ...state,
        ...action.payload,
        activeStepIndex: state.activeStepIndex + 1,
        loading: false,
      };
    }
    case ApplicationActionTypes.SAVE_CLIENT_CREDENTIALS_START: {
      return { ...state, loading: true };
    }
    case ApplicationActionTypes.SAVE_CLIENT_CREDENTIALS_SUCCESS: {
      return { ...state, ...action.payload, loading: false };
    }
    case ApplicationActionTypes.GENERAL_SERVER_FAILURE: {
      return {
        ...state,
        ...action.payload,
        loading: false,
        generateSmsVerificationCodeLoading: false,
        errorOccured: true,
      };
    }
    case ApplicationActionTypes.RELOAD_REQUIRED_FAILURE: {
      return {
        ...state,
        ...action.payload,
        loading: false,
        generateSmsVerificationCodeLoading: false,
        reloadRequired: true,
      };
    }
    case ApplicationActionTypes.GENERATE_VERIFICATION_CODE_START: {
      return { ...state, generateSmsVerificationCodeLoading: true };
    }
    case ApplicationActionTypes.GENERATE_VERIFICATION_CODE_SUCCESS: {
      return {
        ...state,
        ...action.payload,
        generateSmsVerificationCodeLoading: false,
      };
    }
    case ApplicationActionTypes.CHANGE_CONDITION: {
      return { ...state, ...action.payload };
    }
    case ApplicationActionTypes.SAVE_CONTACT_PERSON_DATA_START: {
      return { ...state, loading: true };
    }
    case ApplicationActionTypes.SAVE_CONTACT_PERSON_DATA_SUCCESS: {
      return {
        ...state,
        ...action.payload,
        contactPersonNames:
          action.payload.isContactPersonSameAsApplicantPerson === false
            ? action.payload.contactPersonNames
            : '',
        contactPersonEmail:
          action.payload.isContactPersonSameAsApplicantPerson === false
            ? action.payload.contactPersonEmail
            : '',
        contactMobilePhoneNumber:
          action.payload.isContactPersonSameAsApplicantPerson === false
            ? action.payload.contactMobilePhoneNumber
            : '',
        activeStepIndex: state.activeStepIndex + 1,
        loading: false,
      };
    }
    case ApplicationActionTypes.SAVE_ADDITIONAL_SERVICES_START: {
      return { ...state, loading: true };
    }
    case ApplicationActionTypes.SAVE_ADDITIONAL_SERVICES_SUCCESS: {
      return {
        ...state,
        ...action.payload,
        activeStepIndex: state.activeStepIndex + 1,
        loading: false,
      };
    }
    case ApplicationActionTypes.SAVE_CONTRACT_DATA_START: {
      return { ...state, loading: true };
    }
    case ApplicationActionTypes.SAVE_CONTRACT_DATA_SUCCESS: {
      return {
        ...initialState,
        ...action.payload,
        activeStepIndex: state.activeStepIndex + 1,
        loading: false,
      };
    }
    case ApplicationActionTypes.START_SESSION_START: {
      return { ...state };
    }
    case ApplicationActionTypes.START_SESSION_SUCCESS: {
      return {
        ...state,
        ...action.payload,
        isInitialDataLoaded: true,
        loginErrorMessage: '',
        pendingPasswordReset: false,
      };
    }
    case ApplicationActionTypes.SET_IS_APPLICANT_NAME_OTHER_SELECTED: {
      return { ...state, ...action.payload };
    }
    case ApplicationActionTypes.LOAD_APPLICANT_DATA_START: {
      return { ...state, ...action.payload };
    }
    case ApplicationActionTypes.LOAD_APPLICANT_DATA_SUCCESS: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case ApplicationActionTypes.LOAD_APPLICANT_DATA_FAILURE: {
      return { ...state, ...action.payload, errorOccured: true };
    }
    case ApplicationActionTypes.SAVE_APPLICANT_DATA_START: {
      return { ...state, loading: true };
    }
    case ApplicationActionTypes.SAVE_APPLICANT_DATA_SUCCESS: {
      return {
        ...state,
        ...action.payload,
        activeStepIndex: state.activeStepIndex + 1,
        loading: false,
      };
    }
    case ApplicationActionTypes.SAVE_MERCHANT_DATA_START: {
      return { ...state, loading: true };
    }
    case ApplicationActionTypes.SAVE_MERCHANT_DATA_SUCCESS: {
      return {
        ...state,
        ...action.payload,
        activeStepIndex: state.activeStepIndex + 1,
        loading: false,
        nomenclatures: {
          ...state.nomenclatures,
          steps: action.payload.steps,
        },
      };
    }
    case ApplicationActionTypes.SAVE_ACTIVITY_DATA_START: {
      return { ...state, loading: true };
    }
    case ApplicationActionTypes.SAVE_ACTIVITY_DATA_SUCCESS: {
      return {
        ...state,
        ...action.payload,
        activeStepIndex: state.activeStepIndex + 1,
        loading: false,
      };
    }
    case ApplicationActionTypes.SAVE_IBAN_DATA_START: {
      return { ...state, loading: true };
    }
    case ApplicationActionTypes.SAVE_IBAN_DATA_SUCCESS: {
      return {
        ...state,
        ...action.payload,
        activeStepIndex: action.payload.isIbanFound
          ? state.activeStepIndex + 1
          : state.activeStepIndex,
        loading: false,
      };
    }
    case ApplicationActionTypes.SET_ERROR_OCCURED: {
      return { ...state, ...action.payload };
    }
    case ApplicationActionTypes.CLEAR_APPLICANT_FORM: {
      return { ...state, ...action.payload };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

export default applicationReducer;
