import React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import useStyles from './footer.styles';
import DskLogo from '../../assets/images/dsk_logo.svg';
import OtpLogo from '../../assets/images/otp_logo.svg';

const Footer = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <footer className={classes.footer}>
      <Grid container className={classes.footerContainer}>
        <Grid item className={classes.gridItem}>
          <img src={DskLogo} alt='' className={classes.footerDskLogo} />
          <Box
            component='span'
            display={{ xs: 'none', md: 'inline' }}
            className={classes.footerLogoSeparator}
          >
            |
          </Box>
          <Box
            component='span'
            display={{ xs: 'none', md: 'inline' }}
            className={classes.footerLogoText}
          >
            {t('footer.part-of')}
          </Box>
          <img src={OtpLogo} alt='' className={classes.footerLogoOtp} />
        </Grid>
      </Grid>
    </footer>
  );
};

export default Footer;
