/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { useTranslation } from 'react-i18next';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { Controller } from 'react-hook-form';
import InputAdornment from '@mui/material/InputAdornment';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import useStyles from './phone-number.styles';
import CustomPopper from '../custom-popper/custom-popper.component';
import InfoDialog from '../../info-dialog/info-dialog.component';
import { removeCharacterFromNumber } from '../../../utils/validation.utils';

const PhoneNumber = (p) => {
  const classes = useStyles();
  const {
    options,
    selectedOption,
    selectedOptionOnChange,
    selectorName,
    selectorValidation,
    defaultValue,
    propertyName,
    inputProps,
    inputRef,
    mobilePhoneCountryError,
    mobilePhoneNumberError,
    control,
    disabled,
    infoDialogContent,
    infoDialogTitle,
    showInfoDialogIcon,
  } = p;
  const { t } = useTranslation();

  const [showInfoDialog, setShowInfoDialog] = React.useState(false);

  const infoDialogOpen = () => {
    setShowInfoDialog(true);
  };

  const infoDialogClose = () => {
    setShowInfoDialog(false);
  };

  return (
    <>
      <TextField
        label={`${t('client-data-step.mobile-phone-number')} *`}
        className={classes.root}
        defaultValue={defaultValue}
        name={propertyName}
        disabled={disabled}
        onChange={(e) => {
          e.target.value = removeCharacterFromNumber(e.target.value);
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position='start' className={classes.inputAdornment}>
              <Controller
                name={selectorName}
                defaultValue={selectedOption}
                render={(props) => (
                  <Autocomplete
                    noOptionsText={t('general.autocomplete-no-options')}
                    disabled
                    PopperComponent={CustomPopper}
                    className={classes.innerSelect}
                    value={props.value}
                    options={options}
                    getOptionLabel={(option) =>
                      option.name || options.find((o) => o.id === option).name || ''
                    }
                    isOptionEqualToValue={(option, value) => !!value && option.id === value}
                    onChange={(_, value) => {
                      selectedOptionOnChange(value && value.id);
                      props.onChange(value && value.id);
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                )}
                control={control}
                rules={selectorValidation}
              />
            </InputAdornment>
          ),
          endAdornment: showInfoDialogIcon && (
            <InputAdornment position='end'>
              <InfoOutlinedIcon className={classes.infoIcon} onClick={() => infoDialogOpen()} />
            </InputAdornment>
          ),
        }}
        inputProps={inputProps}
        error={!!mobilePhoneNumberError || !!mobilePhoneCountryError}
        helperText={
          (mobilePhoneNumberError && mobilePhoneNumberError.message) ||
          (mobilePhoneCountryError && mobilePhoneCountryError.message)
        }
        inputRef={inputRef}
      />
      <InfoDialog open={showInfoDialog} title={infoDialogTitle} handleClose={infoDialogClose}>
        {infoDialogContent}
      </InfoDialog>
    </>
  );
};

export default PhoneNumber;
