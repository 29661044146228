import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  stepContentInner: {
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(2),
  },
  root: {
    width: '100%',
  },
  actionsContainer: {
    textAlign: 'right',
    [theme.breakpoints.down('md')]: {
      textAlign: 'center',
    },
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
  button: {
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  buttonForwardOuterWrapper: {
    display: 'inline',
    alignItems: 'center',
  },
  buttonForwardInnerWrapper: {
    position: 'relative',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -10,
    marginLeft: -12,
  },
  stepper: {
    padding: '12px 24px 0 24px',
    [theme.breakpoints.down('md')]: {
      padding: '8px 8px 8px 8px',
    },
  },
  infoIcon: {
    paddingLeft: '4px',
    top: '3px',
    position: 'relative',
    color: '#52ae30',
    cursor: 'pointer',
  },
  initialInfo: {
    paddingLeft: '60px',
    paddingRight: '30px',
    [theme.breakpoints.down('md')]: {
      paddingTop: '30px',
      paddingLeft: '30px',
      paddingRight: '15px',
    },
  },
  initialInfoBox: {
    backgroundColor: '#ECEFF6',
    borderRadius: '24px',
    paddingBottom: '24px',
    lineHeight: '24px',
    paddingRight: '24px',
  },
  mandatoryConditionsBox: {
    marginTop: '2%',
    backgroundColor: '#ECEFF6',
    borderRadius: '24px',
    paddingTop: '24px',
    paddingBottom: '24px',
  },
  mandatoryConditionsIcon: {
    fontSize: '30px',
    transform: 'rotate(180deg)',
    color: '#52AE30',
    paddingBottom: '40px',
  },
  mandatoryConditionsIconGrid: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
      width: '0px !important',
    },
    paddingLeft: '0px !important',
  },
  mandatoryConditionsBoxText: {
    lineHeight: '24px',
    paddingRight: '24px',
  },
  loginBox: {
    boxShadow: '0px 1px 12px #E4E9F0',
    borderRadius: '24px',
    maxWidth: '47%',
    marginRight: '3%',
    marginTop: '2%',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
      marginRight: '0',
    },
    padding: '24px',
  },
  loginBoxText: {
    fontWeight: 'bold',
    lineHeight: '24px',
    fontSize: '18px',
    marginTop: '0px',
  },
  loginButton: {
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
}));

export default useStyles;
