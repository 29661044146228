/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import Grid from '@mui/material/Grid';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import InfoDialog from '../../info-dialog/info-dialog.component';
import {
  useApplicationState,
  useApplicationDispatch,
} from '../../../providers/application/application.provider';
import {
  saveAdditionalServicesAsync,
  changeCondition,
} from '../../../providers/application/application.actions';
import useStyles from './additional-data-step.styles';

const AdditionalDataStep = ({ formRef, currentStepId }) => {
  const { register, handleSubmit } = useForm();
  const classes = useStyles();
  const { t } = useTranslation();

  const {
    authorization,
    refunding,
    payByLink,
    oneClickPayment,
    recurringPayments,
    hasPosCashRegister,
    hasCardPresenceAuthorization,
    hasCardlessAuthorization,
    isReadOnly,
    terminalType,
    nomenclatures: { terminalTypeVirtualPosId },
  } = useApplicationState();
  const dispatch = useApplicationDispatch();

  const onSubmit = (data) => {
    saveAdditionalServicesAsync({ ...data, currentStepId, terminalType }, dispatch);
  };

  const handleCheckboxChange = (e) => {
    dispatch(
      changeCondition({
        [e.target.name]: e.target.checked,
      })
    );
  };

  const [showInfoDialog, setShowInfoDialog] = React.useState(false);
  const [infoDialogContent, setInfoDialogContent] = React.useState('');
  const [infoDialogTitle, setInfoDialogTitle] = React.useState('');

  const infoDialogOpen = (text, title) => {
    setInfoDialogContent(text);
    setInfoDialogTitle(title);
    setShowInfoDialog(true);
  };

  const infoDialogClose = () => {
    setShowInfoDialog(false);
  };

  return (
    <form ref={formRef} onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3}>
        {terminalType === terminalTypeVirtualPosId ? (
          <>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    name='authorization'
                    checked={!!authorization}
                    onClick={handleCheckboxChange}
                    inputRef={register}
                    disabled={isReadOnly}
                  />
                }
                label={<span>{t('additional-data-step.authorization')}</span>}
              />
              <span className={classes.infoIcon}>
                <InfoOutlinedIcon
                  fontSize='inherit'
                  onClick={() =>
                    infoDialogOpen(
                      t('additional-data-step.authorization.info-dialog'),
                      t('additional-data-step.authorization')
                    )
                  }
                />
              </span>
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    name='refunding'
                    checked={!!refunding}
                    onClick={handleCheckboxChange}
                    disabled={isReadOnly}
                    inputRef={register}
                  />
                }
                label={<span>{t('additional-data-step.refunding')}</span>}
              />
              <span className={classes.infoIcon}>
                <InfoOutlinedIcon
                  fontSize='inherit'
                  onClick={() =>
                    infoDialogOpen(
                      t('additional-data-step.refunding.info-dialog'),
                      t('additional-data-step.refunding')
                    )
                  }
                />
              </span>
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    name='payByLink'
                    checked={!!payByLink}
                    onClick={handleCheckboxChange}
                    disabled={isReadOnly}
                    inputRef={register}
                  />
                }
                label={<span>{t('additional-data-step.pay-by-link')}</span>}
              />
              <span className={classes.infoIcon}>
                <InfoOutlinedIcon
                  fontSize='inherit'
                  onClick={() =>
                    infoDialogOpen(
                      t('additional-data-step.pay-by-link.info-dialog'),
                      t('additional-data-step.pay-by-link')
                    )
                  }
                />
              </span>
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    name='oneClickPayment'
                    checked={!!oneClickPayment}
                    onClick={handleCheckboxChange}
                    disabled={isReadOnly}
                    inputRef={register}
                  />
                }
                label={<span>{t('additional-data-step.one-click-payment')}</span>}
              />
              <span className={classes.infoIcon}>
                <InfoOutlinedIcon
                  fontSize='inherit'
                  onClick={() =>
                    infoDialogOpen(
                      t('additional-data-step.one-click-payment.info-dialog'),
                      t('additional-data-step.one-click-payment')
                    )
                  }
                />
              </span>
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    name='recurringPayments'
                    checked={!!recurringPayments}
                    onClick={handleCheckboxChange}
                    disabled={isReadOnly}
                    inputRef={register}
                  />
                }
                label={<span>{t('additional-data-step.recurring-payments')}</span>}
              />
              <span className={classes.infoIcon}>
                <InfoOutlinedIcon
                  fontSize='inherit'
                  onClick={() =>
                    infoDialogOpen(
                      t('additional-data-step.recurring-payments.info-dialog'),
                      t('additional-data-step.recurring-payments')
                    )
                  }
                />
              </span>
            </Grid>
          </>
        ) : (
          <>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    name='hasPosCashRegister'
                    checked={!!hasPosCashRegister}
                    onClick={handleCheckboxChange}
                    disabled={isReadOnly}
                    inputRef={register}
                  />
                }
                label={<span>{t('additional-data-step.pos-cash-register')}</span>}
              />
              <span className={classes.infoIcon}>
                <InfoOutlinedIcon
                  fontSize='inherit'
                  onClick={() =>
                    infoDialogOpen(
                      t('additional-data-step.pos-checkout.info-dialog'),
                      t('additional-data-step.pos-cash-register')
                    )
                  }
                />
              </span>
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    name='refunding'
                    checked={!!refunding}
                    onClick={handleCheckboxChange}
                    disabled={isReadOnly}
                    inputRef={register}
                  />
                }
                label={<span>{t('additional-data-step.refunding')}</span>}
              />
              <span className={classes.infoIcon}>
                <InfoOutlinedIcon
                  fontSize='inherit'
                  onClick={() =>
                    infoDialogOpen(
                      t('additional-data-step.refunding.info-dialog'),
                      t('additional-data-step.refunding')
                    )
                  }
                />
              </span>
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    name='hasCardPresenceAuthorization'
                    checked={!!hasCardPresenceAuthorization}
                    onClick={handleCheckboxChange}
                    disabled={isReadOnly}
                    inputRef={register}
                  />
                }
                label={<span>{t('additional-data-step.card-presence-authorization')}</span>}
              />
              <span className={classes.infoIcon}>
                <InfoOutlinedIcon
                  fontSize='inherit'
                  onClick={() =>
                    infoDialogOpen(
                      t('additional-data-step.card-presence-authorization.info-dialog'),
                      t('additional-data-step.card-presence-authorization')
                    )
                  }
                />
              </span>
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    name='hasCardlessAuthorization'
                    checked={!!hasCardlessAuthorization}
                    onClick={handleCheckboxChange}
                    disabled={isReadOnly}
                    inputRef={register}
                  />
                }
                label={<span>{t('additional-data-step.cardless-authorization')}</span>}
              />
              <span className={classes.infoIcon}>
                <InfoOutlinedIcon
                  fontSize='inherit'
                  onClick={() =>
                    infoDialogOpen(
                      t('additional-data-step.cardless-authorization.info-dialog'),
                      t('additional-data-step.cardless-authorization')
                    )
                  }
                />
              </span>
            </Grid>
          </>
        )}
      </Grid>
      <InfoDialog open={showInfoDialog} title={infoDialogTitle} handleClose={infoDialogClose}>
        {infoDialogContent}
      </InfoDialog>
    </form>
  );
};

export default AdditionalDataStep;
