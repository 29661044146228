import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  gridRelative: {
    position: 'relative',
  },
  subsection: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(3),
  },
  subsectionAddres: {
    margin: theme.spacing(3),
  },
  subsectionLabel: {
    marginLeft: '5px',
    marginBottom: theme.spacing(3),
    fontWeight: 'bolder',
  },
  subsectionInfoIcon: {
    paddingLeft: '3px',
    top: '3px',
    position: 'relative',
    color: '#52ae30',
    cursor: 'pointer',
  },
  emptyGrid: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  activityField: {
    '& div': {
      maxHeight: '100px',
      '& textarea': {
        maxHeight: '70px !important',
        overflow: 'auto !important',
      },
    },
  },
  infoIcon: {
    color: '#52ae30',
    cursor: 'pointer',
  },
  icon: {
    marginRight: '40px',
  },
  radioButton: {
    paddingRight: '10px',
  },
}));

export default useStyles;
